<template>
  <div
    class="container"
    @dragover.prevent="dragOver"
    @dragleave.prevent="dragLeave"
    @drop.prevent="drop($event)"
  >
    <div v-show="dropped == 2" class="drop"></div>
    <!-- Error Message -->
    <div v-show="error" class="error">
      {{ error }}
    </div>
    <!-- To inform user how to upload image -->
    <div v-show="images.length == 0" class="beforeUpload">
      <input
        ref="uploadInput"
        type="file"
        style="z-index: 1"
        accept="image/*"
        multiple
        @change="previewImgs"
      />
      <svg
        style="fill: white"
        class="icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <title>Upload Image</title>
        <g id="Upload_Image" data-name="Upload Image">
          <g id="_Group_" data-name="&lt;Group&gt;">
            <g id="_Group_2" data-name="&lt;Group&gt;">
              <g id="_Group_3" data-name="&lt;Group&gt;">
                <circle
                  id="_Path_"
                  data-name="&lt;Path&gt;"
                  cx="18.5"
                  cy="16.5"
                  r="5"
                  :style="`
                    fill: none;
                    stroke: ${colorBorders};
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  `"
                />
              </g>
              <polyline
                id="_Path_2"
                data-name="&lt;Path&gt;"
                points="16.5 15.5 18.5 13.5 20.5 15.5"
                :style="`
                    fill: none;
                    stroke: ${colorBorders};
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  `"
              />
              <line
                id="_Path_3"
                data-name="&lt;Path&gt;"
                x1="18.5"
                y1="13.5"
                x2="18.5"
                y2="19.5"
                :style="`
                    fill: none;
                    stroke: ${colorBorders};
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  `"
              />
            </g>
            <g id="_Group_4" data-name="&lt;Group&gt;">
              <polyline
                id="_Path_4"
                data-name="&lt;Path&gt;"
                points="0.6 15.42 6 10.02 8.98 13"
                :style="`
                    fill: none;
                    stroke: ${colorBorders};
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  `"
              />
              <polyline
                id="_Path_5"
                data-name="&lt;Path&gt;"
                points="17.16 11.68 12.5 7.02 7.77 11.79"
                :style="`
                    fill: none;
                    stroke: ${colorBorders};
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  `"
              />
              <circle
                id="_Path_6"
                data-name="&lt;Path&gt;"
                cx="8"
                cy="6.02"
                r="1.5"
                :style="`
                    fill: none;
                    stroke: ${colorBorders};
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  `"
              />
              <path
                id="_Path_7"
                data-name="&lt;Path&gt;"
                d="M19.5,11.6V4A1.5,1.5,0,0,0,18,2.5H2A1.5,1.5,0,0,0,.5,4V15A1.5,1.5,0,0,0,2,16.5H13.5"
                :style="`
                    fill: none;
                    stroke: ${colorBorders};
                    stroke-linecap: round;
                    stroke-linejoin: round;
                  `"
              />
            </g>
          </g>
        </g>
      </svg>

      <p class="mainMessage" align="center">
        {{
          uploadMsg
            ? uploadMsg
            : "Haga click para cargar o soltar sus imágenes aquí"
        }}
      </p>
    </div>
    <div
      v-show="images.length > 0"
      class="imgsPreview"
      :class="max == 1 && 'd-flex justify-center'"
    >
      <div
        v-for="(img, i) in images"
        :key="i"
        class="imageHolder"
        :class="largeImg && 'large'"
      >
        <img :src="img" width="300" />
        <div class="delete" @click="deleteImg(--i)">
          <v-icon color="secondary">mdi-window-close</v-icon>
        </div>
        <div
          v-if="++i == images.length"
          v-show="images.length < max && imagesSize(files) < maxMbs"
          class="plus rounded-circle"
          @click="append"
        >
          +
        </div>
      </div>
    </div>
    <p v-if="imagesSize(files) > maxMbs" class="red--text" align="center">
      Ha sobrepasado el límite de MBs ({{ maxMbs }} MBs)
    </p>
  </div>
</template>

<script>
import { fileToBase64 } from "@/Utils/Utils";
/**
 * @todo Refactor this component to erase unnecesary or redundant code.
 */
export default {
  name: "VueUploadImages",
  props: {
    max: { type: Number, default: 0 },
    maxMbs: { type: Number, default: 15 },
    uploadMsg: { type: String, default: "" },
    maxError: { type: String, default: "" },
    fileError: { type: String, default: "" },
    colorBorders: {
      type: String,
      default: "#303c42",
    },
    largeImg: { type: Boolean, default: false },
  },
  emits: ["changed", "imagesChanged"],
  data() {
    return {
      error: "",
      files: [],
      dropped: 0,
      images: [],
    };
  },
  methods: {
    dragOver() {
      this.dropped = 2;
    },
    dragLeave() {},
    drop(e) {
      let status = true;
      let files = Array.from(e.dataTransfer.files);
      if (e && files) {
        files.forEach((file) => {
          if (file.type.startsWith("image") === false) status = false;
        });
        if (status == true) {
          if (
            this.$props.max &&
            files.length + this.files.length > this.$props.max
          ) {
            this.error = this.$props.maxError
              ? this.$props.maxError
              : `El máximo de archivos es` + this.$props.max;
          } else if (
            this.imagesSize(this.files) + this.imagesSize(files) >
            this.$props.maxMbs
          ) {
            this.error = `Ha sobrepasado el límite de MBs (${this.$props.maxMbs} MBs)`;
            return;
          } else {
            this.files.push(...files);
            this.previewImgs();
          }
        } else {
          this.error = this.$props.fileError
            ? this.$props.fileError
            : `Tipo de archivo no soportado`;
        }
      }
      this.dropped = 0;
    },
    append() {
      this.$refs.uploadInput.click();
    },
    readAsDataURL(file) {
      return new Promise(function (resolve, reject) {
        let fr = new FileReader();
        fr.onload = function () {
          resolve(fr.result);
        };
        fr.onerror = function () {
          reject(fr);
        };
        fr.readAsDataURL(file);
      });
    },
    deleteImg(index) {
      this.images.splice(index, 1);
      this.files.splice(index, 1);
      const imagesBase64 = this.splitImageURLs(this.images);

      this.$emit("changed", this.files);
      this.$emit("imagesChanged", {
        imagesBase64: imagesBase64,
        imagesFiles: this.images,
        images: this.files,
      });

      this.$refs.uploadInput.value = null;

      if (this.imagesSize(this.files) > this.$props.maxMbs) {
        this.error = `Ha sobrepasado el límite de MBs (${this.$props.maxMbs} MBs)`;
      } else {
        this.error = "";
      }
    },
    imagesSize(files) {
      let total = 0;
      files.forEach((img) => {
        total += img.size;
      });
      return total / 1024 / 1024;
    },
    async previewImgs(event) {
      if (
        this.$props.max &&
        event &&
        event.currentTarget.files.length + this.files.length > this.$props.max
      ) {
        this.error = this.$props.maxError
          ? this.$props.maxError
          : `El máximo de archivos es` + this.$props.max;
        return;
      }

      if (
        this.imagesSize(this.files) +
          this.imagesSize([...event.currentTarget.files]) >
        this.$props.maxMbs
      ) {
        this.error = `Ha sobrepasado el límite de MBs (${this.$props.maxMbs} MBs)`;
      }

      if (this.dropped == 0) this.files.push(...event.currentTarget.files);
      this.error = "";

      let filename = this.files[0].name;
      let stringFile = await fileToBase64(this.files[0], false);
      this.$emit("changed", {
        fileBase64: stringFile,
        filename: filename,
      });

      let readers = [];
      if (!this.files.length) return;
      for (let i = 0; i < this.files.length; i++) {
        readers.push(fileToBase64(this.files[i]));
      }

      let imagesBase64 = [];
      Promise.all(readers).then((values) => {
        this.images = values;
        imagesBase64 = this.splitImageURLs(values);
        this.$emit("imagesChanged", {
          imagesBase64: imagesBase64,
          imagesFiles: this.images,
          images: this.files,
        });
      });
    },
    reset() {
      this.$refs.uploadInput.value = null;
      this.images = [];
      this.files = [];
      this.$emit("changed", this.files);
    },
    splitImageURLs(images) {
      let imagesBase64 = [];

      for (let i = 0; i < this.files.length; i++) {
        let imageString = images[i].split(",")[1];
        imagesBase64.push({
          fileBase64: imageString,
          filename: this.files[i].name,
        });
      }

      return imagesBase64;
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  position: relative;
}
.drop {
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 10px;
  position: absolute;
  background-color: #f4f6ff;
  left: 0;
  border: 3px dashed #a3a8b1;
}
.error {
  text-align: center;
  font-size: 15px;
}
.beforeUpload {
  position: relative;
  text-align: center;

  input {
    width: 100%;
    margin: auto;
    height: 100%;
    opacity: 0;
    position: absolute;
    background: red;
    display: block;
  }
  .icon {
    width: 150px;
    margin: auto;
    display: block;
  }
}
.imgsPreview {
  .imageHolder {
    &.large {
      width: 180px;
      height: 180px;
    }
    width: 120px;
    height: 120px;
    background: #fff;
    position: relative;
    border-radius: 10px;
    margin: 5px 5px;
    display: inline-block;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .delete {
      position: absolute;
      top: 4px;
      right: 4px;
    }
    .delete:hover,
    .plus:hover,
    .beforeUpload input:hover {
      cursor: pointer;
    }
    .plus {
      font-size: 21pt;
      height: 30px;
      width: 30px;
      text-align: center;
      border: 1px dashed;
      line-height: 26px;
      position: absolute;
      right: -42px;
      bottom: 43%;
    }
  }
}
</style>
