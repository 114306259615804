<template>
  <div class="white--text">
    <div
      class="text-center text-md-start text-h2 text-sm-h1 font-weight-bold mb-10 mb-sm-16"
    >
      Describe los detalles de tu servicio
    </div>
    <div class="text-h4 text-sm-h3 font-weight-bold mb-4">
      Proporciona una descripción general de tu servicio
    </div>
    <div class="mb-8">
      <v-textarea
        v-model="serviceObject.description"
        dark
        outlined
        flat
        dense
        no-resize
        auto-grow
        :rows="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 10 : 7"
        class="input-background rounded-lg v-input--is-focused"
        maxlength="255"
        counter="255"
        :rules="descriptionRules"
        autocomplete="null"
      >
      </v-textarea>
    </div>
  </div>
</template>

<script>
import { descriptionRules } from "@/Utils/rules";
export default {
  name: "DescriptionService",
  props: {
    serviceObject: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      descriptionRules: descriptionRules,
    };
  },
};
</script>

<style lang="scss">
.input-background.v-text-field > .v-input__control > .v-input__slot {
  background-color: rgba(255, 255, 255, 0.3) !important;
}
.input-background.v-text-field
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > input {
  color: white !important;
}
</style>
