<template>
  <v-container fluid ma-0 pa-0>
    <div class="purple-gradient fill-height">
      <Navbar />
    </div>
    <div>
      <div class="header-container px-10 px-md-auto pb-md-0 py-15">
        <div class="d-inline-block d-md-flex align-center pb-10">
          <v-container>
            <div class="text-h2 text-sm-h1 font-weight-bold mb-5">
              Vista previa de servicio
            </div>
            <div class="text-h4">
              Esta vista muestra cómo los usuarios verán tu anuncio al ingresar
              a www.zaturna.com
            </div>
          </v-container>
          <v-spacer></v-spacer>
          <div class="d-md-flex justify-end mt-10 mt-md-0">
            <Button
              text="Atrás"
              outlined
              :disabled="loading"
              :horizontal-padding="6"
              class="ml-3"
              aria-label="Volver un paso atrás"
              @event="backToRegister()"
            />
            <Button
              text="Publicar"
              :disabled="loading"
              class="ml-3"
              aria-label="Publicar servicio"
              @event="publishService()"
            />
          </div>
        </div>
      </div>
      <v-container
        v-if="serviceInfo"
        class="body-container px-10 px-md-auto pb-md-0 py-6"
      >
        <v-container class="pt-10">
          <v-row>
            <v-col
              cols="12"
              :class="`
              d-flex
              justify-space-between
              ${
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                  ? 'flex-column-reverse'
                  : ''
              }
              `"
            >
              <h1 class="text-h1">{{ serviceInfo.serviceName }}</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div
                :class="`d-flex  ${
                  $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                    ? 'flex-column'
                    : 'flex-row'
                } `"
              >
                <span
                  class="mr-sm-8 text-body-1 text-sm-h4 font-weight-regular d-flex d-sm-inline-flex"
                >
                  <span>Proveedor: {{ providerName }}</span>
                </span>
                <span
                  v-if="serviceInfo.city && serviceInfo.state"
                  class="mr-sm-8 text-body-1 text-sm-h4 font-weight-regular d-flex d-sm-inline-flex"
                >
                  <v-icon class="mr-2 secondary--text" small>fa-map-pin</v-icon>
                  <span>{{ serviceInfo.city }}, {{ serviceInfo.state }}</span>
                </span>
                <!-- <span
                class="mr-sm-8 text-subtitle-1 text-sm-h6 font-weight-regular d-flex d-sm-inline-flex"
              >
                <v-icon class="mr-2 secondary--text" small>fa-star</v-icon>
                <span>0 (0 reseñas)</span>
              </span> -->
              </div>
            </v-col>
          </v-row>
          <div class="d-inline-block d-sm-flex mt-6 mb-3 my-sm-0">
            <v-spacer></v-spacer>
            <Button
              text="Cotizar"
              aria-label="Desplazar pantalla al formulario de la solicitud de cotización"
              @event="scroll('QuotationForm')"
            />
          </div>
        </v-container>
        <v-divider class="primary mb-10"></v-divider>
        <v-container>
          <ImageSlideshow
            v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
            :images="serviceInfo.images.imagesFiles"
          />
          <ImageSlideshowTwo
            v-else
            :images="serviceInfo.images.imagesFiles"
          ></ImageSlideshowTwo>
        </v-container>
        <v-divider class="secondary mt-10 mb-15"></v-divider>
        <v-row class="mb-16">
          <v-col cols="12" xl="8" lg="8">
            <div class="text-h3 text-md-h2 font-weight-bold mb-5">
              Información general
            </div>
            <div class="text-body-1">
              {{ serviceInfo.description }}
            </div>
            <div class="my-16 text-body-1">
              <div class="my-5 d-flex d-md-block align-center">
                <v-icon
                  v-if="serviceInfo.allCities"
                  class="mr-3 secondary--text"
                  x-large
                >
                  far fa-check-circle
                </v-icon>
                <v-icon v-else class="mr-3 secondary--text" x-large>
                  far fa-times-circle
                </v-icon>
                <span v-if="serviceInfo.allCities">
                  Da servicio en toda la república.
                </span>
                <span v-else> No da servicio en otras ciudades. </span>
              </div>
              <div class="my-5 d-flex d-md-block align-center">
                <v-icon
                  v-if="serviceInfo.workWithOrganizers"
                  class="mr-3 secondary--text"
                  x-large
                >
                  far fa-check-circle
                </v-icon>
                <v-icon v-else class="mr-3 secondary--text" x-large>
                  far fa-times-circle
                </v-icon>
                <span v-if="serviceInfo.workWithOrganizers">
                  Trabaja con organizadores de eventos.
                </span>
                <span v-else> No trabaja con organizadores de eventos. </span>
              </div>
              <div class="my-5 d-flex d-md-block align-center">
                <v-icon class="mr-3 secondary--text" x-large>
                  far fa-check-circle
                </v-icon>
                <span>
                  Solicita {{ serviceInfo.paymentAdvance }}% de anticipo para
                  reservar fecha.
                </span>
              </div>
              <div class="my-5 d-flex d-md-block align-center">
                <v-icon class="mr-3 secondary--text" x-large>
                  far fa-check-circle
                </v-icon>
                <span>
                  Solicita liquidación del servicio
                  {{ serviceInfo.paymentDeadline }}
                  {{ serviceInfo.paymentDeadline == 1 ? "día" : "días" }}
                  previos al evento.
                </span>
              </div>

              <div
                v-for="(refund, i) in serviceInfo.refundInfo"
                :key="i"
                class="my-5 d-flex d-md-block align-center"
              >
                <v-icon class="mr-3 secondary--text" x-large>
                  far fa-check-circle
                </v-icon>
                <span>
                  Reembolso del {{ serviceInfo.refundInfo[i].discount }}%
                  cancelando
                  {{ serviceInfo.refundInfo[i].days }}
                  {{
                    serviceInfo.refundInfo[i].days == 1
                      ? "día previo"
                      : "días previos"
                  }}
                  al evento.
                </span>
              </div>
              <div
                v-if="
                  serviceInfo.minNumPersons !== 0 ||
                  serviceInfo.maxNumPersons !== 0
                "
                class="my-5 d-flex d-md-block align-center"
              >
                <v-icon class="mr-3 secondary--text" x-large>
                  fas fa-users
                </v-icon>
                <span v-if="serviceInfo.minNumPersons == 0">
                  Hasta {{ serviceInfo.maxNumPersons }}
                  {{
                    serviceInfo.maxNumPersons === 1 ? "persona." : "personas."
                  }}
                </span>
                <span
                  v-else-if="
                    serviceInfo.minNumPersons == serviceInfo.maxNumPersons
                  "
                >
                  {{ serviceInfo.maxNumPersons }}
                  {{
                    serviceInfo.maxNumPersons === 1 ? "persona." : "personas."
                  }}
                </span>
                <span v-else>
                  De {{ serviceInfo.minNumPersons }} a
                  {{ serviceInfo.maxNumPersons }}
                  {{
                    serviceInfo.maxNumPersons === 1 ? "persona." : "personas."
                  }}
                </span>
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="4">
            <QuotationForm
              id="QuotationForm"
              :disable-btn="true"
              :service-id="1"
              service-category="Decoración"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>

<script>
import Navbar from "@/components/Provider/Shared/Navbar.vue";
import Button from "@/components/Shared/Button.vue";
import ImageSlideshow from "@/components/Shared/ImageSlideshow.vue";
import ImageSlideshowTwo from "@/components/Shared/ImageSlideshowTwo.vue";
import QuotationForm from "@/components/User/Service/QuotationForm.vue";
import { mapState } from "vuex";

export default {
  name: "ServicePreview",
  components: {
    Navbar,
    Button,
    ImageSlideshow,
    ImageSlideshowTwo,
    QuotationForm,
  },
  props: {
    serviceInfo: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["previousPage", "publishService"],
  data() {
    return {
      time: ["AM", "PM"],
    };
  },
  computed: mapState({
    providerName(state) {
      return state.provider.name;
    },
  }),
  methods: {
    backToRegister() {
      this.$emit("previousPage");
    },
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
    publishService() {
      this.$emit("publishService");
    },
  },
};
</script>

<style>
.purple-gradient {
  background-color: rgba(98, 37, 130, 1);
}
.header-container {
  background-color: rgba(98, 37, 130, 0.15);
}
</style>
