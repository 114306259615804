<template>
  <div class="white--text">
    <div
      class="text-center text-md-start text-h2 text-sm-h1 font-weight-bold mb-10 mb-sm-16"
    >
      Últimos detalles para publicar tu paquete.
    </div>
    <v-row>
      <v-col cols="12">
        <div class="text-h3 font-weight-bold mb-4">Plan de pagos</div>
        <v-row>
          <v-col cols="12" sm="8" md="6" lg="4">
            <v-select
              v-model="packageInfo.paymentDeadline"
              dark
              outlined
              single-line
              :items="settlementPeriod"
              item-text="label"
              item-value="value"
              item-color="primary"
              class="input-background rounded-lg v-input--is-focused"
              validate-on-blur
              :menu-props="{
                bottom: true,
                closeOnContentClick: true,
                allowOverflow: true,
                offsetY: true,
                rounded: 'md',
                transition: 'slide-y-transition',
              }"
              hide-details="auto"
              autocomplete="null"
              :rules="optionRules"
            >
              <template v-slot:label>
                <span class="white--text"> Periodo de liquidación </span>
              </template>
              <template v-slot:append>
                <span class="v-input__icon--append">
                  <v-icon size="25" color="white" class="v-input__icon--append">
                    mdi-chevron-down
                  </v-icon>
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="6" class="mt-4">
        <div class="text-h3 font-weight-bold mb-4">Política de cancelación</div>
        <v-row class="align-center align-sm-baseline">
          <v-col cols="12" sm="4" md="auto" class="pb-0 text-h4">
            <span>Permitir cancelación con</span>
          </v-col>
          <v-col cols="6" sm="3" md="3" class="pb-0">
            <v-text-field
              v-model="packageInfo.cancellationDeadline"
              dark
              outlined
              type="number"
              min="0"
              step="1"
              pattern="^[0-9]+"
              validate-on-blur
              autocomplete="null"
              placeholder="0"
              hide-details="auto"
              :rules="quantityInput"
              class="input-background rounded-lg v-input--is-focused"
            />
          </v-col>
          <v-col cols="12" sm="5" md="auto" class="text-h4">
            <span>días previos al evento.</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="5" offset-lg="1" class="mt-2 mt-lg-4">
        <div class="text-h3 font-weight-bold mb-4">
          Reembolso por cancelación
        </div>
        <v-row class="align-baseline">
          <v-col cols="12" sm="7" md="9">
            <v-select
              v-model="packageInfo.reimbursement"
              dark
              outlined
              single-line
              :items="refundItems"
              item-text="label"
              item-value="num"
              item-color="primary"
              validate-on-blur
              :menu-props="{
                bottom: true,
                closeOnContentClick: true,
                allowOverflow: true,
                offsetY: true,
                rounded: 'md',
                transition: 'slide-y-transition',
              }"
              hide-details="auto"
              no-data-text="No hay opciones disponibles"
              autocomplete="null"
              :rules="optionRules"
              class="input-background rounded-lg v-input--is-focused"
            >
              <template v-slot:label>
                <span class="'white--text">
                  Elige una opción de reembolso
                </span>
              </template>
              <template v-slot:append>
                <span class="v-input__icon--append">
                  <v-icon size="25" color="white" class="v-input__icon--append">
                    mdi-chevron-down
                  </v-icon>
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="mt-12">
        <div class="text-h3 font-weight-bold mb-4">
          Sube las mejores fotos de tu paquete
        </div>
        <MultImageUpload
          class="d-flex justify-start"
          :max="20"
          upload-msg="Haz click o arrastra una imagen al recuadro (max 20 imágenes)"
          max-error="Solamente puede subir hasta 20 imágenes."
          file-error="Solamente se aceptan imágenes (.png o .jpg)"
          color-borders="white"
          @imagesChanged="updateImages"
        />
        <div v-if="noImages" class="px-3 red--text">
          Debes subir al menos una imagen
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MultImageUpload from "@/components/Shared/MultImageUpload.vue";
import { optionRules, quantityInput } from "@/Utils/rules";

export default {
  name: "FourthStep",
  components: {
    MultImageUpload,
  },
  props: {
    packageInfo: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    noImages: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      refundItems: [
        { label: "Devolución del 0%", num: "0" },
        { label: "Devolución del 10%", num: "10" },
        { label: "Devolución del 20%", num: "20" },
        { label: "Devolución del 30%", num: "30" },
        { label: "Devolución del 40%", num: "40" },
        { label: "Devolución del 50%", num: "50" },
        { label: "Devolución del 60%", num: "60" },
        { label: "Devolución del 70%", num: "70" },
        { label: "Devolución del 80%", num: "80" },
        { label: "Devolución del 90%", num: "90" },
        {
          label: "Devolución del 100%",
          num: "100",
        },
      ],
      settlementPeriod: [
        {
          label: "Liquidación el día del evento",
          value: "0",
        },
        {
          label: "Liquidación 3 días antes",
          value: "3",
        },
        {
          label: "Liquidación 5 días antes",
          value: "5",
        },
        {
          label: "Liquidación 7 días antes",
          value: "7",
        },
        {
          label: "Liquidación 15 días antes",
          value: "15",
        },
        {
          label: "Liquidación 30 días antes",
          value: "30",
        },
      ],
      optionRules: optionRules,
      quantityInput: quantityInput,
    };
  },
  methods: {
    updateImages(images) {
      this.noImages = images.imagesBase64.length == 0;
      this.packageInfo.images = images;
    },
  },
};
</script>

<style lang="scss">
.input-background.v-text-field > .v-input__control > .v-input__slot {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.disabled-input-background.v-text-field > .v-input__control > .v-input__slot {
  opacity: 0.6;
}

.input-background.v-text-field
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > input {
  color: white !important;
}

.day-circle {
  border: 1px solid white;
  background-color: rgba(255, 255, 255, 0.3) !important;
  cursor: pointer;
  user-select: none;
  &.active {
    border: 2px solid var(--v-primary-base);
  }
}

.v-select__selection {
  color: white;
}
</style>
