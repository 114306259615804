<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <div v-show="page === 1">
      <v-form ref="firstStepForm" v-model="valid" lazy-validation>
        <FirstStep :package-info="packageInfo" />
      </v-form>
    </div>
    <div v-show="page === 2" class="mt-md-3">
      <v-form ref="secondStepForm" v-model="valid" lazy-validation>
        <SecondStep
          :package-info="packageInfo"
          :no-schedule="noSchedule"
          :schedule-overlaps="scheduleOverlaps"
          :no-days-of-week="noDaysOfWeek"
          @checkNoDaysOfWeek="checkNoDaysOfWeek"
        />
      </v-form>
    </div>
    <div v-show="page === 3" class="mt-md-3 pb-10">
      <v-form ref="thirdStepForm" v-model="valid" lazy-validation>
        <ThirdStep :package-info="packageInfo" />
      </v-form>
    </div>
    <div v-show="page === 4" class="mt-md-3 pb-10">
      <v-form ref="fourthStepForm" v-model="valid" lazy-validation>
        <FourthStep :package-info="packageInfo" :no-images="noImages" />
      </v-form>
    </div>
    <v-row class="mt-12 mb-16">
      <v-col
        cols="12"
        :md="page === 3 ? 12 : 11"
        class="mt-6 text-center text-sm-left"
      >
        <label
          v-if="isFormError && $vuetify.breakpoint.mdAndDown"
          class="text-body-1 red--text"
        >
          * Error: Por favor, completa correctamente todos los campos
          obligatorios para poder continuar.
        </label>
      </v-col>
      <v-col cols="12" :md="page === 3 ? 12 : 11">
        <div
          class="d-flex flex-column flex-sm-row align-center"
          :class="
            isFirstPackage && page == 1
              ? 'justify-end'
              : 'justify-space-between'
          "
        >
          <div v-if="page == 1" class="mt-6 mt-sm-0 order-2 order-sm-1">
            <div v-if="isFirstPackage && !$vuetify.breakpoint.xs">
              <Button
                text="Omitir"
                outlined
                :horizontal-padding="6"
                color="white"
                class="mr-sm-3"
                aria-label="Omitir alta de primer producto"
                @event="cancelRegister()"
              />
            </div>
            <div v-else>
              <v-hover v-slot="{ hover }">
                <a
                  class="text-decoration-none d-flex align-center"
                  style="color: inherit"
                  @click="cancelRegister"
                >
                  <v-icon
                    v-if="!isFirstPackage"
                    class="mr-n1"
                    color="white"
                    :size="$vuetify.breakpoint.xs ? 30 : 40"
                  >
                    mdi-chevron-left
                  </v-icon>
                  <span
                    class="text-h4 font-weight-bold white--text"
                    :class="hover && 'text-decoration-underline'"
                  >
                    {{ isFirstPackage ? "Omitir" : "Cancelar" }}
                  </span>
                </a>
              </v-hover>
            </div>
          </div>
          <div v-if="page !== 1" class="mt-6 mt-sm-0 order-2 order-sm-1">
            <v-hover v-slot="{ hover }">
              <a
                class="text-decoration-none d-flex align-center ml-n3"
                style="color: inherit"
                :style="loading && 'pointer-events: none; cursor: default;'"
                @click="previousPage"
              >
                <v-icon
                  class="mr-n1"
                  color="white"
                  :size="$vuetify.breakpoint.xs ? 30 : 40"
                >
                  mdi-chevron-left
                </v-icon>
                <span
                  class="text-h4 font-weight-bold white--text"
                  :class="hover && 'text-decoration-underline'"
                >
                  Atrás
                </span>
              </a>
            </v-hover>
          </div>
          <div class="order-1 order-sm-2 d-flex align-center">
            <label
              v-if="isFormError && $vuetify.breakpoint.lgAndUp"
              class="text-body-1 red--text ml-8 ml-md-16 mr-6 mr-md-8"
            >
              * Error: Por favor, completa correctamente todos los campos
              obligatorios para poder continuar.
            </label>
            <Button
              :text="page == 4 ? 'Vista previa' : 'Continuar'"
              :block="$vuetify.breakpoint.xs"
              class="opacity-button"
              :aria-label="
                page == 4
                  ? 'Mostrar vista previa del paquete'
                  : 'Continuar al siguiente paso del registro'
              "
              @event="validate()"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import FirstStep from "@/components/Provider/Register/Package/FirstStep.vue";
import SecondStep from "@/components/Provider/Register/Package/SecondStep.vue";
import ThirdStep from "@/components/Provider/Register/Package/ThirdStep.vue";
import FourthStep from "@/components/Provider/Register/Package/FourthStep.vue";
import Button from "@/components/Shared/Button.vue";
import { mapState } from "vuex";

export default {
  name: "CreatePackage",
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    FourthStep,
    Button,
  },
  props: {
    isFirstPackage: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  emits: ["setPackagePreviewInfo"],
  data() {
    return {
      packageInfo: {
        name: "",
        type: "",
        address: "",
        state: "",
        city: "",
        adultsQuantity: "",
        kidsQuantity: "",
        isExtraCapacity: false,
        extraCapacity: "",
        extraCapacityPrice: "",
        description: "",
        deletedAt: "",
        schedule: [
          { timeStart: "", timeEnd: "" },
          { timeStart: "", timeEnd: "" },
          { timeStart: "", timeEnd: "" },
        ],
        prices: [
          {
            price: "",
            daysOfWeek: [
              { name: "L", value: false },
              { name: "M", value: false },
              { name: "Mi", value: false },
              { name: "J", value: false },
              { name: "V", value: false },
              { name: "S", value: false },
              { name: "D", value: false },
            ],
          },
          {
            price: "",
            daysOfWeek: [
              { name: "L", value: false },
              { name: "M", value: false },
              { name: "Mi", value: false },
              { name: "J", value: false },
              { name: "V", value: false },
              { name: "S", value: false },
              { name: "D", value: false },
            ],
          },
        ],
        items: [
          { quantity: "", name: "" },
          { quantity: "", name: "" },
          { quantity: "", name: "" },
          { quantity: "", name: "" },
          { quantity: "", name: "" },
        ],
        supplementaryItems: [
          { name: "", unit: "", price: "" },
          { name: "", unit: "", price: "" },
          { name: "", unit: "", price: "" },
          { name: "", unit: "", price: "" },
          { name: "", unit: "", price: "" },
        ],
        corkage: false,
        corkagePrice: "",
        extraHour: false,
        extraHourPrice: "",
        dishes: {
          adult: [
            { name: "" },
            { name: "" },
            { name: "" },
            { name: "" },
            { name: "" },
          ],
          kid: [
            { name: "" },
            { name: "" },
            { name: "" },
            { name: "" },
            { name: "" },
          ],
        },
        paymentDeadline: "",
        cancellationDeadline: "",
        reimbursement: "",
        paymentAdvance: 1000,
        images: { imagesBase64: [] },
      },
      noImages: false,
      noSchedule: [false, false, false],
      scheduleOverlaps: false,
      noDaysOfWeek: [false, false],
      valid: true,
      isFormError: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      provider(state) {
        return state.provider;
      },
    }),
  },
  methods: {
    previousPage() {
      this.isFormError = false;
      this.$emit("previousPage");
    },
    nextPage() {
      this.$emit("nextPage");
    },
    isValidHour(val) {
      return /^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(val);
    },
    isValidSchedule(range) {
      const timeStart = this.toDate(this.parseTime(range.timeStart));
      const timeEnd = this.toDate(this.parseTime(range.timeEnd));
      return timeStart >= timeEnd;
    },
    checkNoSchedule() {
      this.noSchedule = this.packageInfo.schedule.map((schedule) => {
        if (
          !this.isValidHour(schedule.timeStart) ||
          !this.isValidHour(schedule.timeEnd)
        ) {
          return false;
        }

        return this.isValidSchedule(schedule);
      });
    },
    parseTime(timeStr) {
      const [hour, minute] = timeStr.split(":").map(Number);
      return { hour, minute };
    },
    toDate(time) {
      const date = new Date();
      date.setHours(time.hour, time.minute, 0, 0);
      return date;
    },
    checkScheduleOverlaps() {
      const filteredSchedule = this.packageInfo.schedule.filter(
        (schedule) =>
          this.isValidHour(schedule.timeStart) &&
          this.isValidHour(schedule.timeEnd)
      );

      if (filteredSchedule.length < 2) {
        this.scheduleOverlaps = false;
        return;
      }

      const sortedSchedule = filteredSchedule.slice().sort((a, b) => {
        const timeStartA = this.toDate(this.parseTime(a.timeStart));
        const timeStartB = this.toDate(this.parseTime(b.timeStart));
        return timeStartA - timeStartB;
      });

      for (let i = 0; i < sortedSchedule.length - 1; i++) {
        const currentTimeEnd = this.toDate(
          this.parseTime(sortedSchedule[i].timeEnd)
        );
        const nextTimeStart = this.toDate(
          this.parseTime(sortedSchedule[i + 1].timeStart)
        );

        if (currentTimeEnd <= nextTimeStart) {
          this.scheduleOverlaps = false;
          return;
        }
      }
      this.scheduleOverlaps = true;
    },
    checkNoDaysOfWeek() {
      this.noDaysOfWeek = this.packageInfo.prices.map((price) => {
        return price.daysOfWeek.every(
          (dayOfWeek) => !dayOfWeek.value && price.price
        );
      });
    },
    validate() {
      this.isFormError = false;

      if (this.page == 1 && this.$refs.firstStepForm.validate()) {
        this.nextPage();
      } else if (this.page == 2) {
        this.checkNoSchedule();
        this.checkScheduleOverlaps();
        this.checkNoDaysOfWeek();

        if (
          this.$refs.secondStepForm.validate() &&
          !this.noSchedule.some((dayOfWeek) => dayOfWeek) &&
          !this.scheduleOverlaps &&
          !this.noDaysOfWeek.some((dayOfWeek) => dayOfWeek)
        ) {
          this.nextPage();
        } else {
          this.isFormError = true;
        }
      } else if (this.page == 3 && this.$refs.thirdStepForm.validate()) {
        this.nextPage();
      } else if (this.page == 4) {
        this.noImages = this.packageInfo.images.imagesBase64.length == 0;

        if (this.$refs.fourthStepForm.validate() && !this.noImages) {
          this.$emit("setPackagePreviewInfo", this.packageInfo);
          this.nextPage();
        } else {
          this.isFormError = true;
        }
      } else {
        this.isFormError = true;
      }
    },
    cancelRegister() {
      if (this.isFirstPackage) {
        this.$emit("redirectRegisterStripe");
      } else {
        this.$router.push({ name: "ProviderProducts" });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.opacity-button.v-btn--active::before {
  opacity: 0;
}
</style>
