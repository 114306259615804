<template>
  <div class="white--text">
    <v-row>
      <v-col cols="12" md="7">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="serviceObject.serviceName"
              dark
              outlined
              class="input-background rounded-lg v-input--is-focused"
              validate-on-blur
              autocomplete="null"
              maxlength="50"
              counter="50"
              :rules="fillInput"
            >
              <template v-slot:label>
                <span class="white--text">Nombre de tu servicio</span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="serviceObject.state"
              :items="states"
              dark
              auto-select-first
              single-line
              outlined
              flat
              color="white"
              background-color="transparent"
              item-color="primary"
              no-data-text="Estado no encontrado"
              :menu-props="{
                bottom: true,
                closeOnContentClick: true,
                allowOverflow: true,
                offsetY: true,
                rounded: 'md',
                transition: 'slide-y-transition',
              }"
              autocomplete="null"
              validate-on-blur
              :rules="stateRulesSelect"
              class="input-background rounded-lg v-input--is-focused"
              @change="stateUpdated"
            >
              <template v-slot:label>
                <span class="white--text"> Estado </span>
              </template>
              <template v-slot:append>
                <span class="v-input__icon--append">
                  <v-icon size="25" color="white" class="v-input__icon--append">
                    mdi-chevron-down
                  </v-icon>
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="serviceObject.city"
              :items="citySelect"
              dark
              auto-select-first
              single-line
              outlined
              flat
              color="white"
              background-color="transparent"
              item-color="primary"
              no-data-text="Seleccione un estado"
              :menu-props="{
                bottom: true,
                closeOnContentClick: true,
                allowOverflow: true,
                offsetY: true,
                rounded: 'md',
                transition: 'slide-y-transition',
              }"
              autocomplete="null"
              validate-on-blur
              :rules="cityRulesSelect"
              class="input-background rounded-lg v-input--is-focused"
            >
              <template v-slot:label>
                <span class="white--text">Ciudad</span>
              </template>
              <template v-slot:append>
                <span class="v-input__icon--append">
                  <v-icon size="25" color="white" class="v-input__icon--append">
                    mdi-chevron-down
                  </v-icon>
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" class="pt-0">
            <!-- <v-checkbox
              v-model="moreCitys"
              color="white"
              :value="true"
              label="Cubrimos eventos en otras ciudades"
              class="pl-2"
              hide-details
              on-icon="mdi-checkbox-outline"
              off-icon="mdi-checkbox-blank-outline white--text"
            >
              <template v-slot:label>
                <span class="white--text">
                  Cubrimos eventos en otras ciudades
                </span>
              </template>
            </v-checkbox> -->
            <v-checkbox
              v-model="serviceObject.allCities"
              color="white"
              class="pl-2"
              hide-details
              on-icon="mdi-checkbox-outline"
              off-icon="mdi-checkbox-blank-outline white--text"
              :ripple="false"
            >
              <template v-slot:label>
                <span class="text-h4 white--text">
                  Cobertura en toda la república
                </span>
              </template>
            </v-checkbox>
            <!-- <v-row v-if="moreCitys">
            <v-col cols="12" xs="12" md="4" sm="12">
              <v-select
                v-model="selectData.state"
                outlined
                :items="states"
                color="white"
                class="input-background rounded-lg"
              >
                <template v-slot:label>
                  <span class="white--text"> Estado </span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" xs="12" md="4" sm="12">
              <v-select
                v-model="selectData.city"
                outlined
                :items="ciudades"
                color="white"
                class="input-background rounded-lg"
              >
                <template v-slot:label>
                  <span class="white--text"> Ciudad </span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" xs="12" md="4" sm="12">
              <v-btn
                :disabled="serviceObject.allCities"
                text
                x-large
                :class="`${
                  $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                    ? 'd-flex justify-center'
                    : ''
                } font-weight-bold mb-15`"
                color="secondary"
                @click="addCityList"
              >
                Agregar
              </v-btn>
            </v-col>
            </v-row>
            <v-row v-if="moreCitys">
              <span
                v-for="citys in serviceObject.locationDeliverList"
                :key="citys.key"
                class="pt-2 pb-2 pl-5 pr-5 ml-5 listBoxes rounded-lg"
              >
                {{ citys.city }}, {{ citys.state }}
                <v-icon class="pl-2" color="white" @click="onDeleteItem(citys)">
                  fas fa-times
                </v-icon>
              </span>
          </v-row> -->
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { states, getCitiesByState } from "@/Utils/locations.js";
import { stateRulesSelect, cityRulesSelect, fillInput } from "@/Utils/rules";

export default {
  props: {
    serviceObject: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      moreCitys: false,
      selectData: { state: "", city: "" },
      states: states,
      stateRulesSelect: stateRulesSelect,
      cityRulesSelect: cityRulesSelect,
      fillInput: fillInput,
    };
  },
  computed: {
    citySelect() {
      return getCitiesByState(this.serviceObject.state);
    },
  },
  methods: {
    stateUpdated() {
      this.serviceObject.city = "";
    },
    addCityList: function () {
      if (
        this.selectData.city !== "" &&
        this.selectData.state !== "" &&
        this.serviceObject.locationDeliverList.findIndex(
          (location) =>
            location.city === this.selectData.city &&
            location.state === this.selectData.state
        )
      ) {
        this.serviceObject.locationDeliverList.push(this.selectData);
        this.selectData = { state: "", city: "" };
      }
    },
    onDeleteItem: function (data) {
      this.serviceObject.locationDeliverList.splice(
        this.serviceObject.locationDeliverList.findIndex(
          (location) =>
            location.city === data.city && location.state === data.state
        ),
        1
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.listBoxes {
  border: 1px solid white;
}
</style>
