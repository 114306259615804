<template>
  <div>
    <div
      v-show="page !== pages"
      class="fill-height purple-gradient white--text"
    >
      <Navbar page="register" />
      <v-progress-linear
        v-if="page !== 1"
        :value="progressValue"
        color="secondary"
        background-color="white"
        :height="$vuetify.breakpoint.xs ? 22 : 13"
        :class="$vuetify.breakpoint.xs && 'progress-linear'"
      >
      </v-progress-linear>
      <v-container class="pt-14">
        <div class="mx-8 mx-sm-12 mx-md-16">
          <div v-if="page === 1">
            <div
              class="text-center text-md-start text-h2 text-sm-h1 font-weight-bold mb-10 mb-sm-16"
            >
              {{ registerProductTitle }}
              <div>
                {{ registerProductSubtitle }}
              </div>
            </div>
            <v-radio-group
              v-if="page === 1"
              v-model="productType"
              dark
              mandatory
              row
            >
              <v-radio value="service" :ripple="false">
                <template v-slot:label>
                  <span class="text-h4 white--text ml-2">Servicio</span>
                </template>
              </v-radio>
              <v-radio value="package" :ripple="false" class="ml-16">
                <template v-slot:label>
                  <span class="text-h4 white--text ml-2">Paquete</span>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
          <CreateService
            v-show="productType === 'service'"
            :is-first-service="isFirstProduct"
            :page="page"
            @previousPage="previousPage"
            @nextPage="nextPage"
            @setImages="setImages"
            @setTagsList="setTagsList"
            @setRefundInfo="setRefundInfo"
            @setServicePreviewInfo="setServicePreviewInfo"
          />
          <CreatePackage
            v-show="productType === 'package'"
            :is-first-package="isFirstProduct"
            :page="page"
            @previousPage="previousPage"
            @nextPage="nextPage"
            @setPackagePreviewInfo="setPackagePreviewInfo"
          />
        </div>
      </v-container>
    </div>
    <div v-if="page === pages" class="preview">
      <div v-if="productType === 'service'">
        <ServicePreview
          :service-info="servicePreviewInfo"
          :loading="loading"
          @previousPage="previousPage"
          @publishService="createService"
        />
      </div>
      <div v-if="productType === 'package'">
        <PackagePreview
          :package-info="packagePreviewInfo"
          :loading="loading"
          @previousPage="previousPage"
          @publishPackage="createPackage"
        />
      </div>
    </div>
    <ModalLoading :dialog="loading" />
  </div>
</template>

<script>
import Navbar from "@/components/Provider/Shared/Navbar.vue";
import CreateService from "@/components/Provider/Register/Service/CreateService.vue";
import ServicePreview from "@/components/Provider/Register/Service/ServicePreview.vue";
import CreatePackage from "@/components/Provider/Register/Package/CreatePackage.vue";
import PackagePreview from "@/components/Provider/Register/Package/PackagePreview.vue";
import ModalLoading from "@/components/Shared/Modal/ModalLoading.vue";

export default {
  name: "CreateProduct",
  components: {
    Navbar,
    CreateService,
    ServicePreview,
    CreatePackage,
    PackagePreview,
    ModalLoading,
  },
  props: {
    isFirstProduct: {
      type: Boolean,
      default: false,
    },
    registerProductTitle: {
      type: String,
      default: "Vamos a dar de alta tu nuevo producto en Zaturna",
    },
    registerProductSubtitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      page: 1,
      pages: 5,
      progressValue: 0,
      productType: "service",
      servicePreviewInfo: {},
      packageToPublish: {},
      packagePreviewInfo: {},
      loading: false,
    };
  },
  methods: {
    setImages(images) {
      this.service.images = images;
    },
    setTagsList(tagsList) {
      this.service.tagsList = tagsList;
    },
    setRefundInfo(refundInfo) {
      this.service.refundInfo = refundInfo;
    },
    nextPage() {
      this.page++;
      this.progressValue += 100 / this.pages;
      this.scrollToTop();
    },
    previousPage() {
      this.page--;
      this.progressValue -= 100 / this.pages;
      this.scrollToTop();
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
      });
    },
    setServicePreviewInfo(serviceInfo) {
      this.servicePreviewInfo = serviceInfo;
    },
    setPackagePreviewInfo(packageInfo) {
      this.packageInfo = packageInfo;

      const daysOfWeek = {
        L: "monday",
        M: "tuesday",
        Mi: "wednesday",
        J: "thursday",
        V: "friday",
        S: "saturday",
        D: "sunday",
      };

      const prices = packageInfo.prices.reduce((pricesAcc, price) => {
        const filteredPrices = price.daysOfWeek
          .filter((dayOfWeek) => dayOfWeek.value && price.price)
          .map((dayOfWeek) => ({
            dayOfWeek: daysOfWeek[dayOfWeek.name],
            price: parseInt(price.price * 100),
          }));
        return pricesAcc.concat(filteredPrices);
      }, []);

      const items = packageInfo.items
        .filter(({ quantity, name }) => quantity && name)
        .map(({ quantity, name }) => ({
          quantity: parseInt(quantity),
          name,
        }));

      const supplementaryItems = packageInfo.supplementaryItems
        .filter(({ name, unit, price }) => name && unit && price)
        .map(({ name, unit, price }) => ({
          name,
          unit,
          price: parseInt(price * 100),
        }));

      const dishes = [
        ...packageInfo.dishes.adult
          .filter((dish) => dish.name)
          .map((dish) => ({
            ...dish,
            isForAdult: true,
          })),
        ...packageInfo.dishes.kid
          .filter((dish) => dish.name)
          .map((dish) => ({
            ...dish,
            isForAdult: false,
          })),
      ];

      const minPrice = prices.reduce((min, price) => {
        return price.price < min ? price.price : min;
      }, prices[0].price);

      this.packagePreviewInfo = {
        ...packageInfo,
        corkagePrice: parseInt(packageInfo.corkagePrice * 100),
        adultsQuantity: parseInt(packageInfo.adultsQuantity) || 0,
        kidsQuantity: parseInt(packageInfo.kidsQuantity) || 0,
        paymentDeadline: parseInt(packageInfo.paymentDeadline),
        paymentAdvance: parseInt(packageInfo.paymentAdvance * 100),
        extraCapacity: parseInt(packageInfo.extraCapacity) || 0,
        adultExtraPrice: parseInt(packageInfo.extraCapacityPrice * 100),
        extraHourPrice: parseInt(packageInfo.extraHourPrice * 100),
        remainingExtraCapacity: parseInt(packageInfo.extraCapacity * 100),
        minPrice: minPrice,
        packageItems: items,
        packageDishes: dishes,
        packageSupplementaryItems: supplementaryItems,
      };
    },
    async createService() {
      this.loading = true;
      let data = await this.$store.dispatch("createService", {
        serviceInfo: this.servicePreviewInfo,
      });
      if (data) {
        if (this.isFirstProduct) {
          this.$emit("redirectRegisterStripe");
        } else {
          this.$router.push({ name: "ProviderProducts" });
        }
      }
      this.loading = false;
    },
    async createPackage() {
      this.loading = true;
      let data = await this.$store.dispatch("createPackage", {
        packageInfo: this.packageInfo,
      });
      if (data) {
        if (this.isFirstProduct) {
          this.$emit("redirectRegisterStripe");
        } else {
          this.$router.push({ name: "ProviderProducts" });
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-text-field--outlined fieldset,
::v-deep .v-select__selection,
::v-deep .v-select__selection--comma,
::v-deep .v-select.v-text-field input {
  color: white !important;
}
::v-deep .preview {
  .v-text-field--outlined fieldset {
    color: var(--v-secondary-base) !important;
  }
  .v-select__selection,
  .v-select__selection--comma,
  .v-select.v-text-field input {
    color: black !important;
  }
}
.purple-gradient {
  min-height: 100vh;
  background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 20%,
      rgba(98, 37, 130, 1) 100%
    ),
    url("../../assets/img/fondo_proveedor.png") no-repeat center center !important;
  background-size: cover !important;
}
.progress-linear {
  position: fixed;
  bottom: -1px;
  z-index: 2;
}
.opacity-button.v-btn--active::before {
  opacity: 0;
}
</style>
