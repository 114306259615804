<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <div v-show="page === 1">
      <v-form ref="registerServiceForm" v-model="valid" lazy-validation>
        <RegisterService :service-object="serviceInfo" />
      </v-form>
    </div>
    <div v-show="page === 2" class="mt-md-3">
      <v-form ref="registerTypeServiceForm" v-model="valid" lazy-validation>
        <RegisterTypeService
          :service-object="serviceInfo"
          :no-refund-info="noRefundInfo"
          :is-min-greater-than-max="isMinGreaterThanMax"
          @refundInfoUpdated="updateRefundInfo"
          @isMinGreaterThanMaxUpdated="updateIsMinGreaterThanMax"
        />
      </v-form>
    </div>
    <div v-show="page === 3" class="mt-md-3 pb-10">
      <v-form ref="imagesServiceForm" v-model="valid" lazy-validation>
        <ImagesService
          :service-object="serviceInfo"
          :no-images="noImages"
          :no-tags-list="noTagsList"
          @imagesUpdated="updateImages"
          @tagsListUpdated="updateTagsList"
        />
      </v-form>
    </div>
    <div v-show="page === 4" class="mt-md-3 pb-10">
      <v-form ref="descriptionServiceForm" v-model="valid" lazy-validation>
        <DescriptionService :service-object="serviceInfo" />
      </v-form>
    </div>
    <v-row class="mt-12 mb-16">
      <v-col cols="12" :md="page == 1 ? 7 : page == 3 ? 11 : 12">
        <div
          class="d-flex flex-column flex-sm-row align-center"
          :class="
            isFirstService && page == 1
              ? 'justify-end'
              : 'justify-space-between'
          "
        >
          <div v-if="page == 1" class="mt-6 mt-sm-0 order-2 order-sm-1">
            <div v-if="isFirstService && !$vuetify.breakpoint.xs">
              <Button
                text="Omitir"
                outlined
                :horizontal-padding="6"
                color="white"
                class="mr-sm-3"
                aria-label="Omitir alta de primer producto"
                @event="cancelRegister()"
              />
            </div>
            <div v-else>
              <v-hover v-slot="{ hover }">
                <a
                  class="text-decoration-none d-flex align-center"
                  style="color: inherit"
                  @click="cancelRegister"
                >
                  <v-icon
                    v-if="!isFirstService"
                    class="mr-n1"
                    color="white"
                    :size="$vuetify.breakpoint.xs ? 30 : 40"
                  >
                    mdi-chevron-left
                  </v-icon>
                  <span
                    class="text-h4 font-weight-bold white--text"
                    :class="hover && 'text-decoration-underline'"
                  >
                    {{ isFirstService ? "Omitir" : "Cancelar" }}
                  </span>
                </a>
              </v-hover>
            </div>
          </div>
          <div v-if="page !== 1" class="mt-6 mt-sm-0 order-2 order-sm-1">
            <v-hover v-slot="{ hover }">
              <a
                class="text-decoration-none d-flex align-center ml-n3"
                style="color: inherit"
                :style="loading && 'pointer-events: none; cursor: default;'"
                @click="previousPage"
              >
                <v-icon
                  class="mr-n1"
                  color="white"
                  :size="$vuetify.breakpoint.xs ? 30 : 40"
                >
                  mdi-chevron-left
                </v-icon>
                <span
                  class="text-h4 font-weight-bold white--text"
                  :class="hover && 'text-decoration-underline'"
                >
                  Atrás
                </span>
              </a>
            </v-hover>
          </div>
          <Button
            :text="page == 4 ? 'Vista previa' : 'Continuar'"
            :block="$vuetify.breakpoint.xs"
            class="opacity-button order-1 order-sm-2"
            :aria-label="
              page == 4
                ? 'Mostrar vista previa del servicio'
                : 'Continuar al siguiente paso del registro'
            "
            @event="validate()"
          />
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import RegisterService from "@/components/Provider/Register/Service/RegisterService.vue";
import RegisterTypeService from "@/components/Provider/Register/Service/RegisterTypeService.vue";
import ImagesService from "@/components/Provider/Register/Service/ImagesService.vue";
import DescriptionService from "@/components/Provider/Register/Service/DescriptionService.vue";
import Button from "@/components/Shared/Button.vue";
import { mapState } from "vuex";

export default {
  name: "CreateService",
  components: {
    RegisterService,
    RegisterTypeService,
    ImagesService,
    DescriptionService,
    Button,
  },
  props: {
    isFirstService: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  emits: ["setServicePreviewInfo"],
  data() {
    return {
      serviceInfo: {
        serviceName: "",
        state: "",
        city: "",
        locationDeliverList: [],
        allCities: false,
        serviceCategory: "",
        payPlan: { advance: 0, deadline: 0 },
        refundInfo: [],
        priceRange: 1,
        workWithOrganizers: false,
        minNumPersons: "",
        maxNumPersons: "",
        tagsList: [],
        images: { imagesBase64: [] },
        description: "",
      },
      valid: true,
      loading: false,
      noRefundInfo: false,
      isMinGreaterThanMax: false,
      noImages: false,
      noTagsList: false,
    };
  },
  computed: {
    ...mapState({
      provider(state) {
        return state.provider;
      },
    }),
  },
  methods: {
    previousPage() {
      this.$emit("previousPage");
    },
    nextPage() {
      this.$emit("nextPage");
    },
    validate() {
      if (this.page == 1 && this.$refs.registerServiceForm.validate()) {
        this.nextPage();
      } else if (this.page == 2) {
        this.noRefundInfo = this.serviceInfo.refundInfo.length == 0;
        this.isMinGreaterThanMax =
          parseInt(this.serviceInfo.minNumPersons) >
          parseInt(this.serviceInfo.maxNumPersons);
        if (
          this.$refs.registerTypeServiceForm.validate() &&
          !this.noRefundInfo &&
          !this.isMinGreaterThanMax
        ) {
          this.nextPage();
        }
      } else if (this.page == 3) {
        this.noImages = this.serviceInfo.images.imagesBase64.length == 0;
        this.noTagsList = this.serviceInfo.tagsList.length == 0;
        if (!this.noImages && !this.noTagsList) {
          this.nextPage();
        }
      } else if (
        this.page == 4 &&
        this.$refs.descriptionServiceForm.validate()
      ) {
        this.$emit("setServicePreviewInfo", this.serviceInfo);
        this.nextPage();
      }
    },
    cancelRegister() {
      if (this.isFirstService) {
        this.$emit("redirectRegisterStripe");
      } else {
        this.$router.push({ name: "ProviderProducts" });
      }
    },
    updateImages(images) {
      this.noImages = images.imagesBase64.length == 0;
      this.serviceInfo.images = images;
    },
    updateTagsList(tagsList) {
      this.noTagsList = tagsList.length == 0;
      this.serviceInfo.tagsList = tagsList;
    },
    updateRefundInfo(refundInfo) {
      this.noRefundInfo = refundInfo.length == 0;
      this.serviceInfo.refundInfo = refundInfo;
    },
    updateIsMinGreaterThanMax(value) {
      this.isMinGreaterThanMax = value;
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.opacity-button.v-btn--active::before {
  opacity: 0;
}
</style>
