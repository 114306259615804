<template>
  <v-container fluid ma-0 pa-0>
    <div class="purple-gradient fill-height">
      <Navbar />
    </div>
    <div>
      <div class="header-container px-10 px-md-auto pb-md-0 py-15">
        <div class="d-inline-block d-md-flex align-center pb-10">
          <v-container>
            <div class="text-h2 text-sm-h1 font-weight-bold mb-5">
              Vista previa de paquete
            </div>
            <div class="text-h4">
              Esta vista muestra cómo los usuarios verán tu anuncio al ingresar
              a www.zaturna.com
            </div>
          </v-container>
          <v-spacer></v-spacer>
          <div class="d-md-flex justify-end mt-10 mt-md-0">
            <Button
              text="Atrás"
              outlined
              :disabled="loading"
              :horizontal-padding="6"
              class="ml-3"
              aria-label="Volver un paso atrás"
              @event="backToRegister()"
            />
            <Button
              text="Publicar"
              :disabled="loading"
              class="ml-3"
              aria-label="Publicar paquete"
              @event="publishPackage()"
            />
          </div>
        </div>
      </div>
      <v-container v-if="packageInfo" class="px-10 px-md-auto pt-16 pb-md-0">
        <div>
          <div class="text-h2 text-md-h1 font-weight-bold mx-0">
            {{ packageInfo.name }}
          </div>
          <div class="flex-row mb-4 ml-md-1">
            <span
              class="mr-sm-8 body-1 text-sm-h4 font-weight-regular d-flex d-sm-inline-flex"
              style="color: inherit"
            >
              <span>
                {{ "Proveedor: " + "&nbsp;" }}
              </span>
              <span class="black--text text-decoration-underline">
                {{ provider.name }}
              </span>
            </span>
            <span
              class="mr-sm-8 text-body-1 text-sm-h4 font-weight-regular d-flex d-sm-inline-flex"
              style="color: inherit"
            >
              <v-icon class="mr-2 secondary--text" small> fa-map-pin </v-icon>
              <span style="color: inherit">
                {{ packageInfo.city }}, {{ packageInfo.state }}
              </span>
            </span>
          </div>

          <div class="d-inline-block d-sm-flex">
            <Button
              class="my-4 my-sm-0"
              text="Volver"
              outlined
              pill
              :height="44"
              prepend-icon="fa-caret-left"
              aria-label="Volver atrás"
            />
            <v-spacer></v-spacer>
            <Button
              text="Reservar"
              :height="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 45 : 50
              "
              aria-label="Desplzar pantalla al formulario de la reservación"
              @event="scroll('ReservationForm')"
            />
          </div>
        </div>
        <v-divider class="mt-7 mb-14 secondary"></v-divider>
        <div class="mt-3">
          <ImageSlideshow
            v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
            :images="formatImagesUrl()"
          />
          <ImageSlideshowTwo v-else :images="formatImagesUrl()" />
          <v-divider class="mb-14 secondary"></v-divider>
          <v-row class="mt-5 mb-16 pb-2">
            <v-col cols="12" sm="6" md="8" class="pr-sm-10 text-body-1">
              <div class="text-h2 font-weight-bold mb-4">
                Descripción del paquete
              </div>
              <div class="text-pre-line">
                {{ packageInfo.description }}
              </div>
              <div class="mt-16">
                <div class="my-7 d-flex d-md-block align-center">
                  <v-icon
                    class="mr-3 secondary--text"
                    x-large
                    :class="
                      packageInfo.corkage
                        ? 'far fa-check-circle'
                        : 'far fa-times-circle'
                    "
                  />
                  <span>
                    {{
                      packageInfo.corkage
                        ? "Ofrece descorche para proveedores externos."
                        : "No ofrece descorche para provedores externos."
                    }}
                  </span>
                </div>
                <div class="my-7 d-flex d-md-block align-center">
                  <v-icon
                    class="mr-3 secondary--text"
                    x-large
                    :class="
                      packageInfo.extraHour
                        ? 'far fa-check-circle'
                        : 'far fa-times-circle'
                    "
                  />
                  <span>
                    {{
                      packageInfo.extraHour
                        ? "Ofrece horas extras."
                        : "No ofrece horas extras."
                    }}
                  </span>
                </div>
                <div class="my-7 d-flex d-md-block align-center">
                  <v-icon class="mr-3 secondary--text" x-large>
                    far fa-check-circle
                  </v-icon>
                  <span>
                    Solicita
                    {{ numberToCurrency(packageInfo.paymentAdvance) }} anticipo
                    para reservar fecha.
                  </span>
                </div>
                <div class="my-7 d-flex d-md-block align-center">
                  <v-icon class="mr-3 secondary--text" x-large>
                    far fa-check-circle
                  </v-icon>
                  <span>
                    Solicita liquidación del paquete
                    {{
                      packageInfo.paymentDeadline == 0
                        ? "el día del evento."
                        : packageInfo.paymentDeadline +
                          (packageInfo.paymentDeadline == 1
                            ? " día previo al evento."
                            : " días previos al evento.")
                    }}
                  </span>
                </div>
                <div
                  v-if="packageInfo.reimbursement > 0"
                  class="my-7 d-flex d-md-block align-center"
                >
                  <v-icon class="mr-3 secondary--text" x-large>
                    far fa-check-circle
                  </v-icon>
                  <span>
                    Reembolso del
                    {{ packageInfo.reimbursement }}% cancelando
                    {{
                      packageInfo.cancellationDeadline == 0
                        ? "el día del evento."
                        : packageInfo.cancellationDeadline +
                          (packageInfo.cancellationDeadline == 1
                            ? " día previo al evento."
                            : " días previos al evento.")
                    }}
                  </span>
                </div>
                <div v-else class="my-7 d-flex d-md-block align-center">
                  <v-icon class="mr-3 secondary--text" x-large>
                    far fa-times-circle
                  </v-icon>
                  <span> No ofrece reembolso. </span>
                </div>
                <div class="mt-7 d-flex d-md-block align-center">
                  <v-icon class="mr-3 secondary--text" x-large>
                    fas fa-users
                  </v-icon>
                  <span>
                    {{ packageInfo.kidsQuantity }}
                    {{ packageInfo.kidsQuantity === 1 ? "niño." : "niños." }} -
                    {{ packageInfo.adultsQuantity }}
                    {{
                      packageInfo.adultsQuantity === 1 ? "adulto." : "adultos."
                    }}
                  </span>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <div class="text-h2 font-weight-bold mb-4">
                Desde {{ numberToCurrency(packageInfo.minPrice) }} MXN
              </div>
              <p class="text-h3 font-weight-bold mb-14">¿Qué incluye?</p>
              <div
                v-for="item in packageInfo.packageItems"
                :key="item.id"
                class="d-flex justify-space-between"
              >
                <p class="mb-0">{{ item.name }}</p>
                <p class="mb-0">{{ item.quantity }}</p>
              </div>
              <div class="d-flex justify-space-between">
                <p class="mb-0">Platillos niños</p>
                <p class="mb-0">{{ packageInfo.kidsQuantity }}</p>
              </div>
              <div class="d-flex justify-space-between">
                <p class="mb-0">Platillos adultos</p>
                <p class="mb-0">{{ packageInfo.adultsQuantity }}</p>
              </div>
            </v-col>
          </v-row>
        </div>
        <ReservationForm
          id="ReservationForm"
          :package-info="packageInfo"
          is-read-only
          class="mb-16 pb-9"
        />
      </v-container>
    </div>
  </v-container>
</template>

<script>
import Navbar from "@/components/Provider/Shared/Navbar.vue";
import Button from "@/components/Shared/Button.vue";
import ImageSlideshow from "@/components/Shared/ImageSlideshow.vue";
import ImageSlideshowTwo from "@/components/Shared/ImageSlideshowTwo.vue";
import ReservationForm from "@/components/User/Package/ReservationForm.vue";
import { numberToCurrency } from "@/Utils/numberFormatter.js";
import { mapState } from "vuex";

export default {
  name: "PackagePreview",
  components: {
    Navbar,
    Button,
    ImageSlideshow,
    ImageSlideshowTwo,
    ReservationForm,
  },
  props: {
    packageInfo: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["previousPage", "publishPackage"],
  computed: {
    ...mapState({
      provider: (state) => state.provider,
    }),
  },
  methods: {
    backToRegister() {
      this.$emit("previousPage");
    },
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
    formatImagesUrl() {
      return this.packageInfo.images.imagesFiles;
    },
    publishPackage() {
      this.$emit("publishPackage");
    },
    numberToCurrency(number) {
      return numberToCurrency(number / 100);
    },
  },
};
</script>

<style>
.purple-gradient {
  background-color: rgba(98, 37, 130, 1);
}
.header-container {
  background-color: rgba(98, 37, 130, 0.15);
}
</style>
