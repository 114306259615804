<template>
  <div class="white--text">
    <div
      class="text-center text-md-start text-h2 text-sm-h1 font-weight-bold mb-10 mb-sm-16"
    >
      ¿Qué especificaciones tiene
      <br />
      tu paquete?
    </div>
    <v-row>
      <v-col cols="12" md="11">
        <v-row>
          <v-col cols="12" class="mb-0 pb-0">
            <h2 class="mb-4 text-h3 font-weight-bold">
              Enlista los elementos que incluye tu paquete
            </h2>
            <v-row
              v-for="(item, i) in packageInfo.items"
              :key="i"
              no-gutters
              class="d-flex align-baseline"
            >
              <v-col cols="auto" class="d-flex align-center pr-2 pr-sm-4">
                <label> {{ i + 1 }}. </label>
              </v-col>
              <v-col
                cols="4"
                sm="2"
                lg="1"
                class="d-flex align-center pb-2 pb-sm-3"
              >
                <v-text-field
                  v-model="item.quantity"
                  dark
                  outlined
                  single-line
                  type="number"
                  min="1"
                  step="1"
                  validate-on-blur
                  placeholder="00"
                  background-color="transparent"
                  autocomplete="null"
                  hide-details="auto"
                  :disabled="!isItemOrder(i)"
                  class="input-background rounded-lg v-input--is-focused"
                  :class="!isItemOrder(i) && 'disabled-input-background'"
                  :rules="
                    i == 0 || (isItemOrder(i) && (item.name || item.quantity))
                      ? quantityInput
                      : []
                  "
                  oninput="if(this.value < 0) this.value = 0;"
                />
              </v-col>
              <v-col cols="" class="pl-2 pl-sm-4 pb-sm-3">
                <v-text-field
                  v-model="item.name"
                  dark
                  solo
                  flat
                  outlined
                  validate-on-blur
                  autocomplete="null"
                  hide-details="auto"
                  placeholder="Ej. Piñata personalizada, pastel, refrescos, botana..."
                  :disabled="!isItemOrder(i)"
                  class="input-background italic-placeholder rounded-lg v-input--is-focused"
                  :class="!isItemOrder(i) && 'disabled-input-background'"
                  :rules="
                    i == 0 || (isItemOrder(i) && (item.name || item.quantity))
                      ? fillInput
                      : []
                  "
                />
              </v-col>
            </v-row>
            <div class="d-flex justify-end">
              <a
                class="text-h4 font-weight-bold secondary--text text-decoration-underline mr-3"
                :class="
                  (!isItemOrder(packageInfo.items.length) ||
                    isItemsLimitExceeded) &&
                  'text--disabled'
                "
                style="user-select: none"
                :style="
                  (!isItemOrder(packageInfo.items.length) ||
                    isItemsLimitExceeded) &&
                  'pointer-events: none; cursor: default'
                "
                @click="addItem()"
              >
                + Agregar
              </a>
            </div>
          </v-col>
          <v-col cols="12" class="mt-2 mb-0 pb-0">
            <h2 class="mb-10 text-h3 font-weight-bold">
              Define los horarios disponibles para tu paquete.
              <label class="ml-2 text-h4">
                Introduzca el horario en formato de 24 horas.
              </label>
            </h2>
            <v-row>
              <v-col
                v-for="(schedule, i) in packageInfo.schedule"
                :key="i"
                cols="12"
                sm="7"
                md="6"
                lg="4"
                :class="
                  i === packageInfo.schedule.length - 1
                    ? 'pl-lg-5'
                    : i === 0
                    ? 'pr-lg-5'
                    : 'pl-md-5 px-lg-5'
                "
              >
                <div class="d-flex align-baseline">
                  <h3 class="mr-6 text-h4">{{ i + 1 }}.</h3>
                  <v-text-field
                    v-model="schedule.timeStart"
                    v-mask="'##:##'"
                    flat
                    solo
                    dark
                    outlined
                    required
                    hide-details="auto"
                    type="text"
                    color="secondary"
                    background-color="transparent"
                    placeholder="00:00"
                    validate-on-blur
                    :disabled="!isScheduleOrder(i)"
                    :rules="
                      (i === 0 && isScheduleOrder(i)) || schedule.timeEnd
                        ? twentyFourHourRules
                        : []
                    "
                    class="input-background rounded-lg v-input--is-focused"
                    :class="!isScheduleOrder(i) && 'disabled-input-background'"
                  />
                  <h3 class="ml-5 ml-md-10 mr-4 mr-md-8 text-h4">a</h3>
                  <v-text-field
                    v-model="schedule.timeEnd"
                    v-mask="'##:##'"
                    flat
                    solo
                    dark
                    outlined
                    required
                    hide-details="auto"
                    type="text"
                    color="secondary"
                    background-color="transparent"
                    placeholder="00:00"
                    validate-on-blur
                    :disabled="!isScheduleOrder(i)"
                    :rules="
                      (i === 0 && isScheduleOrder(i)) || schedule.timeStart
                        ? twentyFourHourRules
                        : []
                    "
                    class="input-background rounded-lg v-input--is-focused"
                    :class="!isScheduleOrder(i) && 'disabled-input-background'"
                  />
                </div>
                <div v-if="noSchedule[i]" class="text-h4 red--text mt-4">
                  La hora de inicio debe ser menor que la hora de finalización.
                </div>
              </v-col>
              <div
                v-if="noSchedule.every((s) => !s) && scheduleOverlaps"
                class="text-h4 red--text mt-4 ml-3"
              >
                Los intervalos de tiempo deben ser consecutivos y no solaparse.
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" class="mt-8">
            <h2 class="mb-4 text-h3 font-weight-bold">
              Define el costo de tu paquete.
            </h2>
            <v-row>
              <v-col
                v-for="(price, i) in packageInfo.prices"
                :key="i"
                cols="12"
                class="mb-4 mb-lg-0"
              >
                <v-row class="d-flex align-end">
                  <v-col
                    cols="12"
                    sm="8"
                    md="8"
                    lg="4"
                    class="d-flex align-center"
                  >
                    <h3 class="mr-4 text-h4">Costo {{ i + 1 }}.</h3>
                    <v-text-field
                      v-model="price.price"
                      flat
                      dark
                      solo
                      outlined
                      type="number"
                      required
                      min="0"
                      pattern="^[0-9]+"
                      hide-details="auto"
                      color="secondary"
                      background-color="transparent"
                      placeholder="0"
                      validate-on-blur
                      :disabled="!isPriceOrder(i)"
                      class="currency-input input-background rounded-lg v-input--is-focused"
                      :class="!isPriceOrder(i) && 'disabled-input-background'"
                      :rules="
                        i == 0 ||
                        price.daysOfWeek.some((dayOfWeek) => dayOfWeek.value)
                          ? moneyRules
                          : []
                      "
                      oninput="if(this.value < 0) this.value = '';"
                    >
                      <template slot="prepend-inner">
                        <span class="mr-n1 white--text"> $ </span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" lg="auto">
                    <div
                      class="text-h4"
                      :class="noDaysOfWeek[i] && 'red--text'"
                    >
                      Selecciona los días correspondientes a ese costo.
                    </div>
                    <div class="d-flex align-center flex-wrap mt-4">
                      <label
                        v-if="$vuetify.breakpoint.lgAndUp"
                        class="mr-4 text-h4"
                      >
                        {{ i + 1 }}.
                      </label>
                      <v-sheet
                        v-for="(dayOfWeek, j) in price.daysOfWeek"
                        :key="j"
                        height="49"
                        width="49"
                        class="day-circle rounded-circle d-flex align-center justify-center text-h3 white--text"
                        :class="[
                          j !== price.daysOfWeek.length - 1 && 'mr-3',
                          $vuetify.breakpoint.xs && 'mb-3',
                          isPriceOrder(i) ? 'cursor-pointer' : 'disabled',
                          dayOfWeek.value && 'active primary--text',
                        ]"
                        @click="
                          isPriceOrder(i) &&
                            changeDayOfWeek(i, j, !dayOfWeek.value)
                        "
                      >
                        {{ dayOfWeek.name }}
                      </v-sheet>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  fillInput,
  optionRules,
  quantityInput,
  twentyFourHourRules,
  moneyRules,
} from "@/Utils/rules";
import VueMask from "v-mask";
import Vue from "vue";

Vue.use(VueMask);
export default {
  name: "SecondStep",
  props: {
    packageInfo: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    noSchedule: {
      type: Array,
      required: true,
    },
    scheduleOverlaps: {
      type: Boolean,
      required: true,
    },
    noDaysOfWeek: {
      type: Array,
      required: true,
    },
  },
  emits: ["checkNoDaysOfWeek"],
  data() {
    return {
      fillInput: fillInput,
      optionRules: optionRules,
      quantityInput: quantityInput,
      twentyFourHourRules: twentyFourHourRules,
      moneyRules: moneyRules,
    };
  },
  computed: {
    isItemsLimitExceeded() {
      return this.packageInfo.items.length >= 20;
    },
  },
  methods: {
    isItemOrder(index) {
      return this.packageInfo.items
        .slice(0, index)
        .every((item) => item.quantity && item.name);
    },
    isValidHour(val) {
      return /^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(val);
    },
    isScheduleOrder(index) {
      return this.packageInfo.schedule
        .slice(0, index)
        .every(
          (schedule) =>
            this.isValidHour(schedule.timeStart) &&
            this.isValidHour(schedule.timeEnd)
        );
    },
    isPriceOrder(index) {
      return this.packageInfo.prices
        .slice(0, index)
        .every(
          (price) =>
            price.price && price.daysOfWeek.some((dayOfWeek) => dayOfWeek.value)
        );
    },
    addItem() {
      if (!this.isItemsLimitExceeded) {
        this.packageInfo.items.push({ quantity: "", name: "" });
      }
    },
    changeDayOfWeek(pricesIndex, dayOfWeekIndex, newValue) {
      this.packageInfo.prices.forEach((currentPrice) => {
        if (currentPrice.daysOfWeek[dayOfWeekIndex].value === newValue) {
          currentPrice.daysOfWeek[dayOfWeekIndex].value = false;
        }
        this.packageInfo.prices[pricesIndex].daysOfWeek[dayOfWeekIndex].value =
          newValue;
      });

      this.$emit("checkNoDaysOfWeek");
    },
  },
};
</script>

<style lang="scss" scoped>
.input-background.v-text-field > .v-input__control > .v-input__slot {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.input-background.v-text-field
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > input {
  color: white !important;
}

.disabled-input-background.v-text-field > .v-input__control > .v-input__slot {
  opacity: 0.6;
}

::v-deep .input-background input::placeholder {
  color: white;
}

::v-deep .italic-placeholder input::placeholder {
  font-style: italic;
}

::v-deep .currency-input input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::v-deep .currency-input input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.day-circle {
  border: 1px solid white;
  background-color: rgba(255, 255, 255, 0.3) !important;
  user-select: none;
  &.active {
    border: 2px solid var(--v-primary-base);
  }
  &.disabled {
    opacity: 0.6;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.v-select__selection {
  color: white;
}
</style>
