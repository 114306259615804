<template>
  <div class="white--text">
    <v-row>
      <v-col cols="12" md="11">
        <v-row>
          <v-col cols="12" sm="6" md="8">
            <v-text-field
              v-model="packageInfo.name"
              dark
              outlined
              class="input-background rounded-lg v-input--is-focused"
              validate-on-blur
              autocomplete="null"
              maxlength="50"
              counter="50"
              :rules="fillInput"
              hide-details="auto"
            >
              <template v-slot:label>
                <span class="white--text">Nombre de tu paquete</span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              v-model="packageInfo.type"
              :items="packageTypes"
              dark
              auto-select-first
              outlined
              single-line
              item-text="name"
              item-color="primary"
              class="input-background rounded-lg v-input--is-focused"
              validate-on-blur
              :menu-props="{
                bottom: true,
                closeOnContentClick: true,
                allowOverflow: true,
                offsetY: true,
                rounded: 'md',
                transition: 'slide-y-transition',
              }"
              no-data-text="No hay tipos de paquete disponibles"
              autocomplete="null"
              hide-details="auto"
              :rules="optionRules"
            >
              <template v-slot:label>
                <span class="white--text">Tipo de evento</span>
              </template>
              <template v-slot:append>
                <span class="v-input__icon--append">
                  <v-icon size="25" color="white" class="v-input__icon--append">
                    mdi-chevron-down
                  </v-icon>
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" class="pb-0">
            <h2 class="mb-4 ml-3 text-h3 font-weight-bold">Dirección</h2>
            <v-text-field
              v-model="packageInfo.address"
              dark
              outlined
              class="input-background rounded-lg v-input--is-focused"
              validate-on-blur
              autocomplete="null"
              :rules="fillInput"
              hide-details="auto"
            />
            <p class="mt-2 ml-6 text-h4">
              Calle, número, colonia y código postal.
            </p>
          </v-col>
          <v-col cols="12" class="mb-0 pt-0 pb-0">
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-autocomplete
                  v-model="packageInfo.state"
                  :items="states"
                  dark
                  auto-select-first
                  single-line
                  outlined
                  flat
                  color="white"
                  background-color="transparent"
                  item-color="primary"
                  no-data-text="Estado no encontrado"
                  :menu-props="{
                    bottom: true,
                    closeOnContentClick: true,
                    allowOverflow: true,
                    offsetY: true,
                    rounded: 'md',
                    transition: 'slide-y-transition',
                  }"
                  autocomplete="null"
                  validate-on-blur
                  :rules="stateRulesSelect"
                  hide-details="auto"
                  class="input-background rounded-lg v-input--is-focused"
                  @change="updateLocation"
                >
                  <template v-slot:label>
                    <span class="white--text"> Estado </span>
                  </template>
                  <template v-slot:append>
                    <span class="v-input__icon--append">
                      <v-icon
                        size="25"
                        color="white"
                        class="v-input__icon--append"
                      >
                        mdi-chevron-down
                      </v-icon>
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-autocomplete
                  v-model="packageInfo.city"
                  :items="citySelect"
                  dark
                  auto-select-first
                  single-line
                  outlined
                  flat
                  color="white"
                  background-color="transparent"
                  item-color="primary"
                  no-data-text="Seleccione un estado"
                  :menu-props="{
                    bottom: true,
                    closeOnContentClick: true,
                    allowOverflow: true,
                    offsetY: true,
                    rounded: 'md',
                    transition: 'slide-y-transition',
                  }"
                  autocomplete="null"
                  validate-on-blur
                  :rules="packageInfo.state ? cityRulesSelect : []"
                  hide-details="auto"
                  :disabled="!packageInfo.state"
                  class="input-background rounded-lg v-input--is-focused"
                  :class="!packageInfo.state && 'disabled-input-background'"
                >
                  <template v-slot:label>
                    <span class="white--text">Ciudad</span>
                  </template>
                  <template v-slot:append>
                    <span class="v-input__icon--append">
                      <v-icon
                        size="25"
                        color="white"
                        class="v-input__icon--append"
                      >
                        mdi-chevron-down
                      </v-icon>
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="8" class="mt-10 pb-0">
            <h2 class="mb-4 ml-1 text-h3 font-weight-bold">Capacidad</h2>
            <v-row>
              <v-col cols="12" sm="6" class="d-flex align-baseline">
                <h3 class="ml-4 mr-7 text-h4">Adultos</h3>
                <v-text-field
                  v-model="packageInfo.adultsQuantity"
                  dark
                  outlined
                  single-line
                  type="number"
                  min="0"
                  max="9999"
                  step="1"
                  class="input-background rounded-lg v-input--is-focused"
                  validate-on-blur
                  placeholder="0"
                  background-color="transparent"
                  autocomplete="null"
                  hide-details="auto"
                  :rules="!packageInfo.kidsQuantity ? quantityInput : []"
                  oninput="if(this.value < 0) this.value = '';"
                />
              </v-col>
              <v-col cols="12" sm="6" class="d-flex align-baseline">
                <h3 class="ml-4 mr-7 text-h4">Niños</h3>
                <v-text-field
                  v-model="packageInfo.kidsQuantity"
                  dark
                  outlined
                  single-line
                  type="number"
                  min="0"
                  step="1"
                  class="input-background rounded-lg v-input--is-focused"
                  validate-on-blur
                  placeholder="0"
                  background-color="transparent"
                  autocomplete="null"
                  hide-details="auto"
                  :rules="!packageInfo.adultsQuantity ? quantityInput : []"
                  oninput="if(this.value < 0) this.value = '';"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="mt-4 mt-lg-0">
            <v-row>
              <v-col
                cols="12"
                md="12"
                lg="auto"
                class="d-flex align-baseline pb-0"
              >
                <h3 class="ml-4 mr-7 text-h4">¿Admites personas extra?</h3>
                <v-radio-group
                  v-model="packageInfo.isExtraCapacity"
                  dark
                  mandatory
                  row
                  hide-details
                  class="pt-0"
                  @change="updateExtraCapacity"
                >
                  <div class="d-flex flex-nowrap">
                    <v-radio :value="false" :ripple="false">
                      <template v-slot:label>
                        <span class="text-h4 white--text ml-1">No</span>
                      </template>
                    </v-radio>
                    <v-radio :value="true" :ripple="false" class="ml-5">
                      <template v-slot:label>
                        <span class="text-h4 white--text ml-1">Si</span>
                      </template>
                    </v-radio>
                  </div>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="6" lg="" class="d-flex align-baseline pb-0">
                <h3 class="ml-4 ml-lg-8 mr-7 text-h4">Cantidad</h3>
                <v-select
                  v-model="packageInfo.extraCapacity"
                  :items="extraCapacityOptions"
                  dark
                  auto-select-first
                  outlined
                  single-line
                  item-text="name"
                  item-color="primary"
                  class="input-background rounded-lg v-input--is-focused"
                  validate-on-blur
                  :menu-props="{
                    bottom: true,
                    closeOnContentClick: true,
                    allowOverflow: true,
                    offsetY: true,
                    rounded: 'md',
                    transition: 'slide-y-transition',
                  }"
                  hide-details="auto"
                  autocomplete="null"
                  :rules="packageInfo.isExtraCapacity ? optionRules : []"
                  :disabled="!packageInfo.isExtraCapacity"
                  :class="
                    !packageInfo.isExtraCapacity && 'disabled-input-background'
                  "
                >
                  <template v-slot:append>
                    <span class="v-input__icon--append">
                      <v-icon
                        size="25"
                        color="white"
                        class="v-input__icon--append"
                      >
                        mdi-chevron-down
                      </v-icon>
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="12"
                sm="9"
                md="8"
                lg="4"
                class="d-flex align-baseline pb-0"
              >
                <h3 class="ml-4 ml-lg-0 mr-7 text-h4">Costo</h3>
                <v-text-field
                  v-model="packageInfo.extraCapacityPrice"
                  flat
                  dark
                  solo
                  outlined
                  type="number"
                  required
                  pattern="^[0-9]+"
                  min="0"
                  hide-details="auto"
                  color="secondary"
                  class="currency-input input-background rounded-lg v-input--is-focused"
                  background-color="transparent"
                  placeholder="0"
                  validate-on-blur
                  :rules="packageInfo.isExtraCapacity ? moneyRules : []"
                  :disabled="!packageInfo.isExtraCapacity"
                  :class="
                    !packageInfo.isExtraCapacity && 'disabled-input-background'
                  "
                  oninput="if(this.value < 0) this.value = '';"
                >
                  <template slot="prepend-inner">
                    <span class="mr-n1 white--text"> $ </span>
                  </template>
                </v-text-field>
                <h3 class="ml-4 mr-7 text-h4">Por persona</h3>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <h2 class="mb-4 ml-1 text-h3 font-weight-bold">
              Proporciona una descripción general del paquete.
            </h2>
            <v-textarea
              v-model="packageInfo.description"
              dark
              outlined
              flat
              dense
              no-resize
              auto-grow
              :rows="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 4 : 5"
              class="input-background rounded-lg v-input--is-focused"
              maxlength="1200"
              counter="1200"
              :rules="fillInput"
              autocomplete="null"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { states, getCitiesByState } from "@/Utils/locations.js";
import {
  stateRulesSelect,
  cityRulesSelect,
  fillInput,
  optionRules,
  quantityInput,
  moneyRules,
} from "@/Utils/rules";
import { mapState } from "vuex";

export default {
  name: "FirstStep",
  props: {
    packageInfo: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      location: { state: "", city: "" },
      states: states,
      stateRulesSelect: stateRulesSelect,
      cityRulesSelect: cityRulesSelect,
      fillInput: fillInput,
      optionRules: optionRules,
      quantityInput: quantityInput,
      moneyRules: moneyRules,
    };
  },
  computed: {
    ...mapState({
      packageTypes(state) {
        return state.packageTypes;
      },
    }),
    extraCapacityOptions() {
      return Array.from({ length: 20 }, (_v, k) => k + 1);
    },
    citySelect() {
      return getCitiesByState(this.packageInfo.state);
    },
    isExtraCapacity() {
      return this.packageInfo.isExtraCapacity;
    },
  },
  methods: {
    updateLocation() {
      this.packageInfo.city = "";
    },
    updateExtraCapacity(value) {
      if (!value) {
        this.packageInfo.extraCapacity = 0;
        this.packageInfo.extraCapacityPrice = "";
      } else {
        this.packageInfo.extraCapacity = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-background.v-text-field > .v-input__control > .v-input__slot {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.input-background.v-text-field
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > input {
  color: white !important;
}

.disabled-input-background.v-text-field > .v-input__control > .v-input__slot {
  opacity: 0.6;
}

::v-deep .input-background input::placeholder {
  color: white;
}

::v-deep .currency-input input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::v-deep .currency-input input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.v-select__selection {
  color: white;
}
</style>
