<template>
  <div class="white--text">
    <div
      class="text-center text-md-start text-h2 text-sm-h1 font-weight-bold mb-10 mb-sm-16"
    >
      La imagen de tu servicio
    </div>
    <div class="text-h4 text-sm-h3 font-weight-bold mb-4">
      Sube las mejores fotos de tu servicio
    </div>
    <div class="mb-8">
      <MultImageUpload
        class="d-flex justify-start"
        :max="20"
        upload-msg="Haz click o arrastra una imagen al recuadro (max 20 imágenes)"
        max-error="Solamente puede subir hasta 20 imágenes."
        file-error="Solamente se aceptan imágenes (.png o .jpg)"
        color-borders="white"
        @imagesChanged="updateImages"
      />
      <div v-if="noImages" class="px-3 red--text">
        Debes subir al menos una imagen
      </div>
    </div>
    <div class="text-h4 text-sm-h3 font-weight-bold mb-1">
      Elige etiquetas que describan a tu servicio
    </div>
    <div>Máximo 20, elige las que más describan a tu servicio.</div>
    <v-row class="mt-1 align-baseline">
      <v-col cols="12" sm="8" md="6">
        <v-text-field
          v-model="tagInput"
          dark
          outlined
          single-line
          class="input-background rounded-lg v-input--is-focused"
          validate-on-blur
          autocomplete="null"
          hide-details
          maxlength="20"
          @keydown.enter.prevent="addTags"
        >
          <template v-slot:label>
            <span class="white--text"> Ej. flores, arreglos florales </span>
          </template>
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        md="6"
        class="pt-0 pt-sm-auto text-center text-sm-start"
      >
        <a
          class="text-decoration-underline secondary--text font-weight-medium"
          style="user-select: none"
          :style="!maxTags && 'pointer-events: none; cursor: default'"
          :class="!maxTags && 'text--disabled'"
          @click="addTags"
        >
          Agregar
        </a>
      </v-col>
      <v-col v-if="!noTagsList" cols="12" class="mb-2">
        <div class="ml-3 d-flex flex-wrap">
          <span
            v-for="(tag, index) in tagsList"
            :key="index"
            class="pb-3 mr-4 mr-md-8"
          >
            <span class="d-flex align-center">
              {{ index + 1 }}) {{ tag }}
              <a class="pl-sm-1" @click="removeTag(index)">
                <v-icon color="white">mdi-close</v-icon>
              </a>
            </span>
          </span>
        </div>
      </v-col>
      <v-col v-else cols="12" class="mb-2">
        <div class="red--text">
          La lista de etiquetas no puede estar vacía. Favor de agregar al menos
          una etiqueta.
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MultImageUpload from "@/components/Shared/MultImageUpload.vue";

export default {
  name: "ImagesService",
  components: {
    MultImageUpload,
  },
  props: {
    serviceObject: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    noImages: {
      type: Boolean,
      required: true,
    },
    noTagsList: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["imagesUpdated", "tagsListUpdated"],
  data() {
    return {
      tagInput: "",
      tagsList: [],
    };
  },
  computed: {
    maxTags() {
      return () => (this.serviceObject.tagsList.length === 20 ? true : false);
    },
  },
  methods: {
    addTags() {
      if (
        this.tagInput !== "" &&
        this.tagsList.findIndex((tag) => tag === this.tagInput)
      ) {
        this.tagsList.push(this.tagInput);
        this.$emit("tagsListUpdated", this.tagsList);
        this.tagInput = "";
      }
    },
    removeTag(index) {
      this.tagsList.splice(
        this.tagsList.findIndex((tag, id) => id === index),
        1
      );
      this.$emit("tagsListUpdated", this.tagsList);
    },
    updateImages(images) {
      this.$emit("imagesUpdated", images);
    },
  },
};
</script>

<style lang="scss">
.input-background.v-text-field > .v-input__control > .v-input__slot {
  background-color: rgba(255, 255, 255, 0.3) !important;
}
.input-background.v-text-field
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > input {
  color: white !important;
}
.listBoxes {
  border: 1px solid white;
}
.v-select__selection {
  color: white;
}
</style>
