var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white--text"},[_vm._m(0),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"11"}},[_c('v-row',[_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-4 text-h3 font-weight-bold"},[_vm._v(" Enlista los elementos que incluye tu paquete ")]),_vm._l((_vm.packageInfo.items),function(item,i){return _c('v-row',{key:i,staticClass:"d-flex align-baseline",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center pr-2 pr-sm-4",attrs:{"cols":"auto"}},[_c('label',[_vm._v(" "+_vm._s(i + 1)+". ")])]),_c('v-col',{staticClass:"d-flex align-center pb-2 pb-sm-3",attrs:{"cols":"4","sm":"2","lg":"1"}},[_c('v-text-field',{staticClass:"input-background rounded-lg v-input--is-focused",class:!_vm.isItemOrder(i) && 'disabled-input-background',attrs:{"dark":"","outlined":"","single-line":"","type":"number","min":"1","step":"1","validate-on-blur":"","placeholder":"00","background-color":"transparent","autocomplete":"null","hide-details":"auto","disabled":!_vm.isItemOrder(i),"rules":i == 0 || (_vm.isItemOrder(i) && (item.name || item.quantity))
                    ? _vm.quantityInput
                    : [],"oninput":"if(this.value < 0) this.value = 0;"},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})],1),_c('v-col',{staticClass:"pl-2 pl-sm-4 pb-sm-3",attrs:{"cols":""}},[_c('v-text-field',{staticClass:"input-background italic-placeholder rounded-lg v-input--is-focused",class:!_vm.isItemOrder(i) && 'disabled-input-background',attrs:{"dark":"","solo":"","flat":"","outlined":"","validate-on-blur":"","autocomplete":"null","hide-details":"auto","placeholder":"Ej. Piñata personalizada, pastel, refrescos, botana...","disabled":!_vm.isItemOrder(i),"rules":i == 0 || (_vm.isItemOrder(i) && (item.name || item.quantity))
                    ? _vm.fillInput
                    : []},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1)],1)}),_c('div',{staticClass:"d-flex justify-end"},[_c('a',{staticClass:"text-h4 font-weight-bold secondary--text text-decoration-underline mr-3",class:(!_vm.isItemOrder(_vm.packageInfo.items.length) ||
                  _vm.isItemsLimitExceeded) &&
                'text--disabled',staticStyle:{"user-select":"none"},style:((!_vm.isItemOrder(_vm.packageInfo.items.length) ||
                  _vm.isItemsLimitExceeded) &&
                'pointer-events: none; cursor: default'),on:{"click":function($event){return _vm.addItem()}}},[_vm._v(" + Agregar ")])])],2),_c('v-col',{staticClass:"mt-2 mb-0 pb-0",attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-10 text-h3 font-weight-bold"},[_vm._v(" Define los horarios disponibles para tu paquete. "),_c('label',{staticClass:"ml-2 text-h4"},[_vm._v(" Introduzca el horario en formato de 24 horas. ")])]),_c('v-row',[_vm._l((_vm.packageInfo.schedule),function(schedule,i){return _c('v-col',{key:i,class:i === _vm.packageInfo.schedule.length - 1
                  ? 'pl-lg-5'
                  : i === 0
                  ? 'pr-lg-5'
                  : 'pl-md-5 px-lg-5',attrs:{"cols":"12","sm":"7","md":"6","lg":"4"}},[_c('div',{staticClass:"d-flex align-baseline"},[_c('h3',{staticClass:"mr-6 text-h4"},[_vm._v(_vm._s(i + 1)+".")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"}],staticClass:"input-background rounded-lg v-input--is-focused",class:!_vm.isScheduleOrder(i) && 'disabled-input-background',attrs:{"flat":"","solo":"","dark":"","outlined":"","required":"","hide-details":"auto","type":"text","color":"secondary","background-color":"transparent","placeholder":"00:00","validate-on-blur":"","disabled":!_vm.isScheduleOrder(i),"rules":(i === 0 && _vm.isScheduleOrder(i)) || schedule.timeEnd
                      ? _vm.twentyFourHourRules
                      : []},model:{value:(schedule.timeStart),callback:function ($$v) {_vm.$set(schedule, "timeStart", $$v)},expression:"schedule.timeStart"}}),_c('h3',{staticClass:"ml-5 ml-md-10 mr-4 mr-md-8 text-h4"},[_vm._v("a")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"}],staticClass:"input-background rounded-lg v-input--is-focused",class:!_vm.isScheduleOrder(i) && 'disabled-input-background',attrs:{"flat":"","solo":"","dark":"","outlined":"","required":"","hide-details":"auto","type":"text","color":"secondary","background-color":"transparent","placeholder":"00:00","validate-on-blur":"","disabled":!_vm.isScheduleOrder(i),"rules":(i === 0 && _vm.isScheduleOrder(i)) || schedule.timeStart
                      ? _vm.twentyFourHourRules
                      : []},model:{value:(schedule.timeEnd),callback:function ($$v) {_vm.$set(schedule, "timeEnd", $$v)},expression:"schedule.timeEnd"}})],1),(_vm.noSchedule[i])?_c('div',{staticClass:"text-h4 red--text mt-4"},[_vm._v(" La hora de inicio debe ser menor que la hora de finalización. ")]):_vm._e()])}),(_vm.noSchedule.every(function (s) { return !s; }) && _vm.scheduleOverlaps)?_c('div',{staticClass:"text-h4 red--text mt-4 ml-3"},[_vm._v(" Los intervalos de tiempo deben ser consecutivos y no solaparse. ")]):_vm._e()],2)],1),_c('v-col',{staticClass:"mt-8",attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-4 text-h3 font-weight-bold"},[_vm._v(" Define el costo de tu paquete. ")]),_c('v-row',_vm._l((_vm.packageInfo.prices),function(price,i){return _c('v-col',{key:i,staticClass:"mb-4 mb-lg-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"d-flex align-end"},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"8","md":"8","lg":"4"}},[_c('h3',{staticClass:"mr-4 text-h4"},[_vm._v("Costo "+_vm._s(i + 1)+".")]),_c('v-text-field',{staticClass:"currency-input input-background rounded-lg v-input--is-focused",class:!_vm.isPriceOrder(i) && 'disabled-input-background',attrs:{"flat":"","dark":"","solo":"","outlined":"","type":"number","required":"","min":"0","pattern":"^[0-9]+","hide-details":"auto","color":"secondary","background-color":"transparent","placeholder":"0","validate-on-blur":"","disabled":!_vm.isPriceOrder(i),"rules":i == 0 ||
                      price.daysOfWeek.some(function (dayOfWeek) { return dayOfWeek.value; })
                        ? _vm.moneyRules
                        : [],"oninput":"if(this.value < 0) this.value = '';"},model:{value:(price.price),callback:function ($$v) {_vm.$set(price, "price", $$v)},expression:"price.price"}},[_c('template',{slot:"prepend-inner"},[_c('span',{staticClass:"mr-n1 white--text"},[_vm._v(" $ ")])])],2)],1),_c('v-col',{attrs:{"cols":"12","lg":"auto"}},[_c('div',{staticClass:"text-h4",class:_vm.noDaysOfWeek[i] && 'red--text'},[_vm._v(" Selecciona los días correspondientes a ese costo. ")]),_c('div',{staticClass:"d-flex align-center flex-wrap mt-4"},[(_vm.$vuetify.breakpoint.lgAndUp)?_c('label',{staticClass:"mr-4 text-h4"},[_vm._v(" "+_vm._s(i + 1)+". ")]):_vm._e(),_vm._l((price.daysOfWeek),function(dayOfWeek,j){return _c('v-sheet',{key:j,staticClass:"day-circle rounded-circle d-flex align-center justify-center text-h3 white--text",class:[
                        j !== price.daysOfWeek.length - 1 && 'mr-3',
                        _vm.$vuetify.breakpoint.xs && 'mb-3',
                        _vm.isPriceOrder(i) ? 'cursor-pointer' : 'disabled',
                        dayOfWeek.value && 'active primary--text' ],attrs:{"height":"49","width":"49"},on:{"click":function($event){_vm.isPriceOrder(i) &&
                          _vm.changeDayOfWeek(i, j, !dayOfWeek.value)}}},[_vm._v(" "+_vm._s(dayOfWeek.name)+" ")])})],2)])],1)],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center text-md-start text-h2 text-sm-h1 font-weight-bold mb-10 mb-sm-16"},[_vm._v(" ¿Qué especificaciones tiene "),_c('br'),_vm._v(" tu paquete? ")])}]

export { render, staticRenderFns }