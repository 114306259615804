<template>
  <div class="white--text">
    <div
      class="text-center text-md-start text-h2 text-sm-h1 font-weight-bold mb-10 mb-sm-16"
    >
      ¿Qué tipo de servicio quieres promocionar?
    </div>
    <v-row>
      <v-col cols="12" md="6">
        <div class="text-h4 text-sm-h3 font-weight-bold mb-4">
          ¿A qué categoría pertenece tu servicio?
        </div>
        <v-row>
          <v-col cols="12" md="8">
            <v-autocomplete
              v-model="serviceObject.serviceCategory"
              :items="categories"
              dark
              auto-select-first
              outlined
              single-line
              item-text="name"
              item-color="primary"
              class="input-background rounded-lg v-input--is-focused"
              validate-on-blur
              :menu-props="{
                bottom: true,
                closeOnContentClick: true,
                allowOverflow: true,
                offsetY: true,
                rounded: 'md',
                transition: 'slide-y-transition',
              }"
              no-data-text="No hay categorias disponibles"
              autocomplete="null"
              :rules="categoryRules"
            >
              <template v-slot:label>
                <span class="white--text">Elige una categoría</span>
              </template>
              <template v-slot:append>
                <span class="v-input__icon--append">
                  <v-icon size="25" color="white" class="v-input__icon--append">
                    mdi-chevron-down
                  </v-icon>
                </span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <div class="text-h4 text-sm-h3 font-weight-bold mb-4">
          Plan de pagos
        </div>
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              v-model="serviceObject.paymentAdvance"
              :items="advancePercentage"
              dark
              outlined
              single-line
              item-text="label"
              item-value="value"
              item-color="primary"
              class="input-background rounded-lg v-input--is-focused"
              validate-on-blur
              :menu-props="{
                bottom: true,
                closeOnContentClick: true,
                allowOverflow: true,
                offsetY: true,
                rounded: 'md',
                transition: 'slide-y-transition',
              }"
              hide-details="auto"
              autocomplete="null"
              :rules="optionRules"
            >
              <template v-slot:label>
                <span class="white--text">Porcentaje de anticipo</span>
              </template>
              <template v-slot:append>
                <span class="v-input__icon--append">
                  <v-icon size="25" color="white" class="v-input__icon--append">
                    mdi-chevron-down
                  </v-icon>
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="serviceObject.paymentDeadline"
              dark
              outlined
              single-line
              :items="settlementPeriod"
              item-text="label"
              item-value="value"
              item-color="primary"
              class="input-background rounded-lg v-input--is-focused"
              validate-on-blur
              :menu-props="{
                bottom: true,
                closeOnContentClick: true,
                allowOverflow: true,
                offsetY: true,
                rounded: 'md',
                transition: 'slide-y-transition',
              }"
              autocomplete="null"
              :rules="optionRules"
            >
              <template v-slot:label>
                <span class="white--text"> Periodo de liquidación </span>
              </template>
              <template v-slot:append>
                <span class="v-input__icon--append">
                  <v-icon size="25" color="white" class="v-input__icon--append">
                    mdi-chevron-down
                  </v-icon>
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <div class="text-h4 text-sm-h3 font-weight-bold mb-4">
          Política de cancelación
        </div>
        <v-row class="align-center align-sm-baseline">
          <v-col cols="12" sm="4" md="auto" class="pb-0 text-h4">
            <span>Permitir cancelación con</span>
          </v-col>
          <v-col cols="6" sm="3" md="3" class="pb-0">
            <v-text-field
              v-model="refundData.days"
              dark
              outlined
              class="input-background rounded-lg v-input--is-focused"
              validate-on-blur
              autocomplete="null"
              placeholder="0"
              hide-details="auto"
              :disabled="checkNoMoreThanOneCancelation"
              :rules="refundData.days !== null ? quantityInput : []"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="5" md="auto" class="text-h4">
            <span>días previos al evento.</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" class="mb-6">
        <div class="text-h4 text-sm-h3 font-weight-bold mb-4">
          Reembolso por cancelación
        </div>
        <v-row class="align-baseline">
          <v-col cols="12" sm="7" md="9">
            <v-select
              v-model="refundData.discount"
              dark
              outlined
              single-line
              :items="refundList"
              item-text="label"
              item-value="num"
              item-color="primary"
              class="input-background rounded-lg v-input--is-focused"
              validate-on-blur
              :menu-props="{
                bottom: true,
                closeOnContentClick: true,
                allowOverflow: true,
                offsetY: true,
                rounded: 'md',
                transition: 'slide-y-transition',
              }"
              hide-details
              no-data-text="No hay opciones disponibles"
              autocomplete="null"
              :disabled="checkNoMoreThanOneCancelation"
            >
              <template v-slot:label>
                <span :class="!checkNoMoreThanOneCancelation && 'white--text'">
                  Elige una opción de reembolso
                </span>
              </template>
              <template v-slot:append>
                <span class="v-input__icon--append">
                  <v-icon size="25" color="white" class="v-input__icon--append">
                    mdi-chevron-down
                  </v-icon>
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="auto" class="text-center">
            <a
              class="text-decoration-underline secondary--text font-weight-medium mb-4 ml-sm-3"
              style="user-select: none"
              :style="
                checkNoMoreThanOneCancelation &&
                'pointer-events: none; cursor: default'
              "
              :class="checkNoMoreThanOneCancelation && 'text--disabled'"
              @click="addRefundInfoItem"
            >
              Agregar
            </a>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="refundInfo.length !== 0" cols="12" class="mb-6">
        <div
          v-for="(info, index) in serviceObject.refundInfo"
          :key="index"
          class="mb-1"
        >
          Cancelación con
          <span class="font-weight-bold">{{ info.days }} </span>
          días previos al evento. Devolución del
          <span class="font-weight-bold">{{ info.discount }}</span>
          %
          <a
            text
            class="text-decoration-underline secondary--text font-weight-medium mb-4 ml-3"
            style="user-select: none"
            @click="deleteRefundInfoItem(index)"
          >
            Eliminar
          </a>
        </div>
      </v-col>
      <v-col v-else-if="noRefundInfo" cols="12" class="mb-3">
        <div class="red--text">
          La lista de política de cancelación no puede estar vacía. Favor de dar
          click en agregar una vez que los campos estén llenos.
        </div>
      </v-col>
      <v-col cols="12" class="mb-3">
        <div class="text-h4 text-sm-h3 font-weight-bold mb-4">
          ¿Cuál es el rango de precios de tu servicio?
        </div>
        <v-rating
          v-model="serviceObject.priceRange"
          :value="serviceObject.priceRange"
          hover
          length="5"
          size="30"
          :ripple="false"
          empty-icon="fa-dollar-sign text--lighten-4"
          full-icon="fa-dollar-sign secondary--text"
          color="white"
          background-color="white"
        ></v-rating>
      </v-col>
      <v-col cols="12">
        <div class="text-h4 text-sm-h3 font-weight-bold mb-4">
          ¿Sueles trabajar con organizadores de eventos?
        </div>

        <v-radio-group
          v-model="serviceObject.workWithOrganizers"
          dark
          mandatory
          row
        >
          <v-radio :value="true" :ripple="false">
            <template v-slot:label>
              <span class="white--text">Si</span>
            </template>
          </v-radio>
          <v-radio :value="false" :ripple="false">
            <template v-slot:label>
              <span class="white--text">No</span>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12">
        <div class="text-h4 text-sm-h3 font-weight-bold mb-4">
          ¿Cuántas personas por evento puedes llegar a cubrir?
        </div>
        <v-row class="align-baseline" no-gutters>
          <v-col cols="auto">De</v-col>
          <v-col sm="3" md="4" lg="2">
            <v-text-field
              v-model="serviceObject.minNumPersons"
              dark
              outlined
              single-line
              type="number"
              min="0"
              step="1"
              class="input-background rounded-lg v-input--is-focused mx-3"
              validate-on-blur
              placeholder="0"
              background-color="transparent"
              autocomplete="null"
              :rules="[...minRules, ...quantityInput]"
              oninput="if(this.value < 0) this.value = 0;"
              @change="
                handleMinNumPersonsFormat();
                handleNumPersonsFormat();
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="auto">a</v-col>
          <v-col sm="3" md="4" lg="2">
            <v-text-field
              v-model="serviceObject.maxNumPersons"
              dark
              outlined
              single-line
              type="number"
              min="0"
              step="1"
              class="input-background rounded-lg v-input--is-focused mx-3"
              validate-on-blur
              placeholder="0"
              background-color="transparent"
              autocomplete="null"
              :rules="[...maxRules, ...quantityInput]"
              oninput="if(this.value < 0) this.value = 0;"
              @change="
                handleMaxNumPersonsFormat();
                handleNumPersonsFormat();
              "
            >
            </v-text-field>
          </v-col>
          personas.
          <v-col v-if="isMinGreaterThanMax" cols="12" class="mb-3">
            <div class="red--text">
              La capacidad máxima debe ser mayor o igual a la capacidad mínima.
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  categoryRules,
  optionRules,
  quantityInput,
  minRules,
  maxRules,
} from "@/Utils/rules";
import { mapState } from "vuex";

export default {
  name: "RegisterTypeService",
  props: {
    serviceObject: {
      type: Object,
      require: true,
      default: () => ({}),
    },
    noRefundInfo: {
      type: Boolean,
      required: true,
    },
    isMinGreaterThanMax: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["refundInfoUpdated", "isMinGreaterThanMaxUpdated"],
  data() {
    return {
      refundList: [
        { label: "Devolución del 0%", num: "0" },
        { label: "Devolución del 10%", num: "10" },
        { label: "Devolución del 20%", num: "20" },
        { label: "Devolución del 30%", num: "30" },
        { label: "Devolución del 40%", num: "40" },
        { label: "Devolución del 50%", num: "50" },
        { label: "Devolución del 60%", num: "60" },
        { label: "Devolución del 70%", num: "70" },
        { label: "Devolución del 80%", num: "80" },
        { label: "Devolución del 90%", num: "90" },
        {
          label: "Devolución del 100%",
          num: "100",
        },
      ],
      advancePercentage: [
        {
          label: "30% anticipo",
          value: "30",
        },
        {
          label: "50% anticipo",
          value: "50",
        },
        {
          label: "100% anticipo",
          value: "100",
        },
      ],
      settlementPeriod: [
        {
          label: "Liquidación el día del evento",
          value: "0",
        },
        {
          label: "Liquidación 3 días antes",
          value: "3",
        },
        {
          label: "Liquidación 5 días antes",
          value: "5",
        },
        {
          label: "Liquidación 7 días antes",
          value: "7",
        },
        {
          label: "Liquidación 15 días antes",
          value: "15",
        },
        {
          label: "Liquidación 30 días antes",
          value: "30",
        },
      ],
      deadlines: [
        {
          label: "Liquidación 7 días antes",
          num: "7",
        },
        {
          label: "Liquidación 15 días antes",
          num: "15",
        },
        {
          label: "Liquidación 30 días antes",
          num: "30",
        },
      ],
      refundData: {
        days: null,
        discount: null,
      },
      categoryRules: categoryRules,
      optionRules: optionRules,
      quantityInput: quantityInput,
      minRules: minRules,
      maxRules: maxRules,
      refundRules: [],
      refundInfo: [],
    };
  },
  computed: {
    ...mapState({
      categories(state) {
        return state.categories;
      },
    }),
    checkNoMoreThanOneCancelation() {
      return this.serviceObject.refundInfo.length >= 1;
    },
  },
  methods: {
    addRefundInfoItem() {
      if (
        /^\d+$/.test(this.refundData.days) &&
        this.refundData.days !== 0 &&
        this.refundData.discount !== null &&
        this.serviceObject.refundInfo.findIndex(
          (options) => this.refundData.id === options.id
        )
      ) {
        this.refundInfo.push(this.refundData);
        this.$emit("refundInfoUpdated", this.refundInfo);
        this.refundData = {
          days: null,
          discount: null,
        };
      }
    },
    deleteRefundInfoItem(id) {
      this.refundInfo.splice(
        this.serviceObject.refundInfo.findIndex((item, index) => index === id),
        1
      );
      this.$emit("refundInfoUpdated", this.refundInfo);
    },
    handleNumPersonsFormat() {
      this.$emit("isMinGreaterThanMaxUpdated", false);
      if (
        this.serviceObject.minNumPersons &&
        this.serviceObject.maxNumPersons
      ) {
        this.$emit(
          "isMinGreaterThanMaxUpdated",
          parseInt(this.serviceObject.minNumPersons) >
            parseInt(this.serviceObject.maxNumPersons)
        );
      }
    },
    handleMinNumPersonsFormat() {
      this.serviceObject.minNumPersons =
        this.serviceObject.minNumPersons.replace(
          /^(?:0+(?=[1-9])|0+(?=0$))|,/gm,
          ""
        );
    },
    handleMaxNumPersonsFormat() {
      this.serviceObject.maxNumPersons =
        this.serviceObject.maxNumPersons.replace(
          /^(?:0+(?=[1-9])|0+(?=0$))|,/gm,
          ""
        );
    },
  },
};
</script>

<style lang="scss">
.input-background.v-text-field > .v-input__control > .v-input__slot {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.input-background.v-text-field
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > input {
  color: white !important;
}
.v-select__selection {
  color: white;
}
.btn-text-style:before {
  opacity: 1 !important;
}
</style>
