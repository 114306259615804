<template>
  <div class="white--text">
    <div
      class="text-center text-md-start text-h2 text-sm-h1 font-weight-bold mb-10 mb-sm-16"
    >
      ¿Qué elementos adicionales
      <br />
      ofreces para tu paquete?
    </div>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" class="mb-0 pb-0">
            <h2 class="mb-4 text-h3 font-weight-bold">
              Enlista los elementos que se pueden añadir a tu paquete con un
              costo extra.
            </h2>
            <v-row
              v-if="$vuetify.breakpoint.smAndUp"
              no-gutters
              class="mb-2 ml-sm-7"
            >
              <v-col cols="4" sm="6" lg="8" class="pl-sm-3 pr-sm-4">
                <h4 class="text-h4">Elemento</h4>
              </v-col>
              <v-col cols="4" sm="" class="pl-sm-3">
                <h4 class="text-h4">Variación</h4>
              </v-col>
              <v-col cols="4" sm="2" lg="1" class="pl-sm-3">
                <h4 class="text-h4">Costo</h4>
              </v-col>
            </v-row>
            <v-row
              v-for="(supplementaryItem, i) in packageInfo.supplementaryItems"
              :key="i"
              no-gutters
              class="d-flex align-baseline mb-8 mb-sm-0"
            >
              <v-col cols="auto" class="d-flex align-center pr-2 pr-sm-4">
                <label> {{ i + 1 }}. </label>
              </v-col>
              <v-col>
                <v-row no-gutters>
                  <v-col cols="12" sm="6" lg="8" class="pb-3 pr-sm-4">
                    <h4 v-if="$vuetify.breakpoint.xs" class="text-h4 ml-3 mb-2">
                      Elemento
                    </h4>
                    <v-text-field
                      v-model="supplementaryItem.name"
                      dark
                      solo
                      flat
                      outlined
                      validate-on-blur
                      autocomplete="null"
                      hide-details="auto"
                      placeholder="Ej. Pastel. decoración, invitación, carrito snacks..."
                      :disabled="!isSupplementaryItemOrder(i)"
                      :rules="
                        isSupplementaryItemOrder(i) &&
                        (supplementaryItem.name ||
                          supplementaryItem.unit ||
                          supplementaryItem.price)
                          ? fillInput
                          : []
                      "
                      class="input-background italic-placeholder rounded-lg v-input--is-focused"
                      :class="
                        !isSupplementaryItemOrder(i) &&
                        'disabled-input-background'
                      "
                    />
                  </v-col>
                  <v-col class="pb-3 pr-2 pr-sm-4">
                    <h4 v-if="$vuetify.breakpoint.xs" class="text-h4 ml-3 mb-2">
                      Variación
                    </h4>
                    <v-text-field
                      v-model="supplementaryItem.unit"
                      dark
                      solo
                      flat
                      outlined
                      validate-on-blur
                      autocomplete="null"
                      placeholder="Ej. Chico, 40 piezas..."
                      :disabled="!isSupplementaryItemOrder(i)"
                      :rules="
                        isSupplementaryItemOrder(i) &&
                        (supplementaryItem.name ||
                          supplementaryItem.unit ||
                          supplementaryItem.price)
                          ? fillInput
                          : []
                      "
                      hide-details="auto"
                      class="input-background italic-placeholder rounded-lg v-input--is-focused"
                      :class="
                        !isSupplementaryItemOrder(i) &&
                        'disabled-input-background'
                      "
                    />
                  </v-col>
                  <v-col cols="4" sm="2" lg="1" class="pb-3">
                    <h4 v-if="$vuetify.breakpoint.xs" class="text-h4 ml-3 mb-2">
                      Costo
                    </h4>
                    <v-text-field
                      v-model="supplementaryItem.price"
                      dark
                      solo
                      flat
                      outlined
                      type="number"
                      required
                      pattern="^[0-9]+"
                      min="0"
                      hide-details="auto"
                      color="secondary"
                      background-color="transparent"
                      validate-on-blur
                      placeholder="0"
                      :disabled="!isSupplementaryItemOrder(i)"
                      class="currency-input input-background rounded-lg v-input--is-focused"
                      :class="
                        !isSupplementaryItemOrder(i) &&
                        'disabled-input-background'
                      "
                      :rules="
                        isSupplementaryItemOrder(i) &&
                        (supplementaryItem.name ||
                          supplementaryItem.unit ||
                          supplementaryItem.price)
                          ? moneyRules
                          : []
                      "
                      oninput="if(this.value < 0) this.value = '';"
                    >
                      <template slot="prepend-inner">
                        <span class="mr-n1 white--text"> $ </span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div class="d-flex justify-end mt-3 mr-3">
              <a
                class="text-h4 font-weight-bold secondary--text text-decoration-underline"
                :class="
                  (!isSupplementaryItemOrder(
                    packageInfo.supplementaryItems.length
                  ) ||
                    isSupplementaryItemsLimitExceeded) &&
                  'text--disabled'
                "
                style="user-select: none"
                :style="
                  (!isSupplementaryItemOrder(
                    packageInfo.supplementaryItems.length
                  ) ||
                    isSupplementaryItemsLimitExceeded) &&
                  'pointer-events: none; cursor: default'
                "
                @click="addSupplementaryItem()"
              >
                + Agregar
              </a>
            </div>
          </v-col>
          <v-col cols="12" md="7" class="mt-10 mt-md-5 pt-0">
            <v-row no-gutters class="d-flex align-center">
              <v-col cols="12" lg="auto" class="pr-11 mb-sm-2 mb-lg-0">
                <h3 class="ml-lg-7 text-h4">¿Se puede agregar descorche?</h3>
              </v-col>
              <v-col cols="12" sm="auto" class="pr-10">
                <v-radio-group
                  v-model="packageInfo.corkage"
                  dark
                  mandatory
                  row
                  @change="updateCorkage"
                >
                  <div class="d-flex flex-nowrap">
                    <v-radio :value="false" :ripple="false">
                      <template v-slot:label>
                        <span class="text-h4 white--text ml-1">No</span>
                      </template>
                    </v-radio>
                    <v-radio :value="true" :ripple="false" class="ml-5">
                      <template v-slot:label>
                        <span class="text-h4 white--text ml-1">Si</span>
                      </template>
                    </v-radio>
                  </div>
                </v-radio-group>
              </v-col>
              <v-col cols="auto" md>
                <v-text-field
                  v-model="packageInfo.corkagePrice"
                  flat
                  dark
                  solo
                  outlined
                  type="number"
                  required
                  pattern="^[0-9]+"
                  min="0"
                  hide-details="auto"
                  color="secondary"
                  class="currency-input input-background rounded-lg v-input--is-focused"
                  background-color="transparent"
                  placeholder="0"
                  validate-on-blur
                  :rules="packageInfo.corkage ? moneyRules : []"
                  :disabled="!packageInfo.corkage"
                  :class="!packageInfo.corkage && 'disabled-input-background'"
                  oninput="if(this.value < 0) this.value = '';"
                >
                  <template slot="prepend-inner">
                    <span class="mr-n1 white--text"> $ </span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="8" class="mt-5 pt-0">
            <v-row no-gutters class="d-flex align-center">
              <v-col cols="12" lg="auto" class="pr-11 mb-sm-2 mb-lg-0">
                <h3 class="ml-lg-7 text-h4">¿Se puede agregar hora extra?</h3>
              </v-col>
              <v-col cols="12" sm="auto" class="pr-10">
                <v-radio-group
                  v-model="packageInfo.extraHour"
                  dark
                  mandatory
                  row
                  @change="updateExtraHour"
                >
                  <div class="d-flex flex-nowrap">
                    <v-radio :value="false" :ripple="false">
                      <template v-slot:label>
                        <span class="text-h4 white--text ml-1">No</span>
                      </template>
                    </v-radio>
                    <v-radio :value="true" :ripple="false" class="ml-5">
                      <template v-slot:label>
                        <span class="text-h4 white--text ml-1">Si</span>
                      </template>
                    </v-radio>
                  </div>
                </v-radio-group>
              </v-col>
              <v-col cols="auto" md>
                <v-text-field
                  v-model="packageInfo.extraHourPrice"
                  flat
                  dark
                  solo
                  outlined
                  type="number"
                  required
                  pattern="^[0-9]+"
                  min="0"
                  hide-details="auto"
                  color="secondary"
                  class="currency-input input-background rounded-lg v-input--is-focused"
                  background-color="transparent"
                  placeholder="0"
                  validate-on-blur
                  :rules="packageInfo.extraHour ? moneyRules : []"
                  :disabled="!packageInfo.extraHour"
                  :class="!packageInfo.extraHour && 'disabled-input-background'"
                  oninput="if(this.value < 0) this.value = '';"
                >
                  <template slot="prepend-inner">
                    <span class="mr-n1 white--text"> $ </span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col md="auto">
                <h3 class="ml-4 text-h4">Por hora</h3>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="mt-5 mb-0 pb-0">
            <h2 class="mb-7 text-h3 font-weight-bold">
              Define los platillos disponibles para tu paquete.
            </h2>
            <v-row>
              <v-col
                v-if="packageInfo.adultsQuantity > 0"
                cols="12"
                sm="8"
                md="6"
                :lg="packageInfo.kidsQuantity > 0 ? '' : 6"
              >
                <h4 class="text-h4 font-weight-bold mb-2 ml-10">Adultos</h4>
                <v-row
                  v-for="(adultDish, i) in packageInfo.dishes.adult"
                  :key="i"
                  no-gutters
                  class="d-flex align-baseline"
                >
                  <v-col cols="auto" class="d-flex align-center pr-2 pr-sm-4">
                    <label> {{ i + 1 }}. </label>
                  </v-col>
                  <v-col class="pb-2 pb-sm-3">
                    <v-text-field
                      v-model="adultDish.name"
                      dark
                      solo
                      flat
                      outlined
                      validate-on-blur
                      autocomplete="null"
                      hide-details="auto"
                      placeholder="Ej. Panini, hamburguesa, torta..."
                      :disabled="!isAdultDishOrder(i)"
                      class="input-background italic-placeholder rounded-lg v-input--is-focused"
                      :class="
                        !isAdultDishOrder(i) && 'disabled-input-background'
                      "
                      :rules="
                        i == 0 || (isAdultDishOrder(i) && adultDish.name)
                          ? fillInput
                          : []
                      "
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                v-if="packageInfo.kidsQuantity > 0"
                cols="12"
                sm="8"
                md="6"
                :lg="packageInfo.adultsQuantity > 0 ? '' : 6"
                :offset-lg="packageInfo.adultsQuantity > 0 ? 1 : 0"
              >
                <h4 class="text-h4 font-weight-bold mb-2 ml-10">Niños</h4>
                <v-row
                  v-for="(kidDish, i) in packageInfo.dishes.kid"
                  :key="i"
                  no-gutters
                  class="d-flex align-baseline"
                >
                  <v-col cols="auto" class="d-flex align-center pr-2 pr-sm-4">
                    <label> {{ i + 1 }}. </label>
                  </v-col>
                  <v-col class="pb-2 pb-sm-3">
                    <v-text-field
                      v-model="kidDish.name"
                      dark
                      solo
                      flat
                      outlined
                      validate-on-blur
                      autocomplete="null"
                      hide-details="auto"
                      placeholder="Ej. Panini, hamburguesa, torta..."
                      :disabled="!isKidDishOrder(i)"
                      class="input-background italic-placeholder rounded-lg v-input--is-focused"
                      :class="!isKidDishOrder(i) && 'disabled-input-background'"
                      :rules="
                        i == 0 || (isKidDishOrder(i) && kidDish.name)
                          ? fillInput
                          : []
                      "
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { fillInput, moneyRules } from "@/Utils/rules";

export default {
  name: "ThirdStep",
  props: {
    packageInfo: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      fillInput: fillInput,
      moneyRules: moneyRules,
    };
  },
  computed: {
    isSupplementaryItemsLimitExceeded() {
      return this.packageInfo.supplementaryItems.length >= 20;
    },
  },
  methods: {
    isSupplementaryItemOrder(index) {
      return this.packageInfo.supplementaryItems
        .slice(0, index)
        .every(
          (supplementaryItem) =>
            supplementaryItem.name &&
            supplementaryItem.unit &&
            supplementaryItem.price
        );
    },
    addSupplementaryItem() {
      if (!this.isSupplementaryItemsLimitExceeded) {
        this.packageInfo.supplementaryItems.push({
          name: "",
          unit: "",
          price: "",
        });
      }
    },
    updateCorkage(value) {
      if (!value) {
        this.packageInfo.corkagePrice = "";
      }
    },
    updateExtraHour(value) {
      if (!value) {
        this.packageInfo.extraHourPrice = "";
      }
    },
    isAdultDishOrder(index) {
      return this.packageInfo.dishes.adult
        .slice(0, index)
        .every((adultDish) => adultDish.name);
    },
    isKidDishOrder(index) {
      return this.packageInfo.dishes.kid
        .slice(0, index)
        .every((kidDish) => kidDish.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-background.v-text-field > .v-input__control > .v-input__slot {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.input-background.v-text-field
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > input {
  color: white !important;
}

.disabled-input-background.v-text-field > .v-input__control > .v-input__slot {
  opacity: 0.6;
}

::v-deep .input-background input::placeholder {
  color: white;
}

::v-deep .italic-placeholder input::placeholder {
  font-style: italic;
}

::v-deep .currency-input input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::v-deep .currency-input input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}
</style>
